.crazyAd {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999;
  background: white;
  padding: 0px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-items: center;
  align-items: center;

  .crazyAdContainer {
    position: relative;
    display: block;
    margin: auto;
    width: 100%;
    height: 100vh;
    height: -webkit-fill-available;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  img {
    max-width: 100%;
    margin: auto;
    width: 100%;
  }

  .text {
    font-size: 14px;
    background: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    text-align: center;
    position: fixed;
  }

  .counter {
    top: 0px;
    right: -20px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    margin: 0;
  }
  .skipBtn {
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 80px;
    height: 25px;
    border-radius: 25px;
    border: 0px;
  }
}
