.banner {
  background-color: #006241;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  .info {
    padding: 26px 32px;
    .title {
      font-size: 14px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.4px;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 4px;
    }
    .value {
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      color: #fff;
      margin-bottom: 8px;
    }
    .date {
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.5);
    }
  }
  img {
    position: relative;
    right: -8px;
    bottom: -15px;
  }
}

.list {
  margin-bottom: 100px !important;
  width: 100%;
  background-color: #fff;
  padding: 16px;
  display: flex;
  flex-direction: column !important;
  gap: 10px;
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
    background-color: #f9f9f9;
    border-radius: 10px;
  }
  .flag {
    display: flex;
    align-items: center;
    gap: 12px;
    img {
      width: 36px;
      height: auto;
    }
    .name {
      font-size: 16px;
      font-weight: 600;
      color: var(--mui-palette-black-black058);
    }
  }
  .count {
    font-size: 18px;
    font-weight: 600;
    color: var(--mui-palette-black-black087);
  }
}
