.profile {
  &Container {
    overflow: auto;
  }
  &Banner {
    width: 100%;
    min-height: 125px;
    aspect-ratio: 375 / 125;
    padding: 16px;
    background: var(--mui-palette-gradient-green);
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    position: relative;
    & > img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
    & button {
      position: relative;
      padding: 0;
    }
  }
  &Actions {
    padding: 10px 16px;
    height: 58px;
    position: relative;
    background-color: var(--mui-palette-background-default);
    .profileAvatar {
      position: absolute;
      left: 16px;
      bottom: 0;
      width: 80px;
      height: 80px;
      aspect-ratio: 1/1;
      // background-color: var(--mui-palette-background-default);
      border: 3px solid var(--mui-palette-white-opacity100);
    }
  }
  &Section {
    width: 100%;
    padding: 16px;
    background-color: var(--mui-palette-background-default);
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
    & &Info {
      color: var(--mui-palette-text-primary);
      p {
        padding-top: 4px;
        color: var(--mui-palette-black-black058);
        &:global(.status) {
          padding-top: 16px;
          color: var(--mui-palette-green-starbucks)
        }
      }
    }
    & &Title {
      font-weight: 700;
      color: var(--mui-palette-green-house)
    }
    & &Item {
      padding: 15px 0;
      margin-bottom: 8px;
      color: var(--mui-palette-text-primary);
      &:last-child {
        margin-bottom: 0;
      }
      &Label {
        opacity: 0.4;
      }
      &Content {
        
      }
      &Tag {
        height: auto;
        min-height: 30px;
        & > :global(.MuiChip-label){
          white-space: normal;
          height: auto;
        }
      }
      &TagList {
        padding-top: 8px;
        gap: 8px;
      }
    }
  }
}

button.saveButton {
  min-width: auto;
  width: auto;
  padding: 0;
}

.form {
  &Container {
    overflow: hidden;
  }
  &Section {
    background-color: var(--mui-palette-background-default);
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
    & &Title {
      padding-bottom: 8px;
      font-weight: 700;
      color: var(--mui-palette-green-house)
    }
  }
  &Wrapper {
    padding: 10px 0;
  }
  &Field {
    padding: 6px 16px;
    &__fullWidth {
      width: 100%;
      padding: 0;
    }
    &__avatar {
      width: 80px;
      height: 80px;
      padding: 0;
      margin: 0 auto;
      margin-top: -40px;
    }
  }
}

.editProfile {
  &Container {
    overflow: auto;
  }
  
  &Section {
    width: 100%;
    padding: 16px;
    background-color: var(--mui-palette-background-default);
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
    & &Title {
      padding-bottom: 8px;
      font-weight: 700;
      color: var(--mui-palette-green-house)
    }
    & &Item {
      padding: 15px 0;
      margin-bottom: 8px;
      color: var(--mui-palette-text-primary);
      &:last-child {
        margin-bottom: 0;
      }
      &Label {
        opacity: 0.4;
      }
      &Content {
        
      }
      [data-mode=tag] {
        .profileSectionItemLabel {
          padding: 8px 0;
        }
        .profileSectionItemContent {
          padding-top: 4px;
        }
      }
      &TagList > :not(style) + :not(style) {
        margin: 0;
        margin-left: 8px;
      }
      &Tag {
        background-color: var(--mui-palette-green-light);
        color: var(--mui-palette-green-starbucks);
        font-size: 14px;
      }
    }
  }
}

.reminderPopup {
  &Container {
    padding: 16px;
    padding-bottom: 24px;
    & [class*=checkbox] {
      p {
        color: var(--mui-palette-black-black026);;
      }
    }
  }
}
