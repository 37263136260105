.searchFieldSection {
  padding: 16px;
  gap: 16px;
  background-color: var(--mui-palette-background-default);
  border-bottom: 1px solid var(--mui-palette-customGrey-natural);
  .searchField {
    width: 100%;
    height: 34px;
    min-height: auto;
    background: var(--mui-palette-customGrey-natural);
    border-radius: 100px;
    padding: 0 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--mui-palette-black-black087);
    &:before,
    &:after {
      display: none;
    }
    input {
      padding-top: 8px;
      padding-bottom: 8px;
      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        display: none;
      }
    }
  }
  .searchIcon {
    img {
      width: 16px;
      height: 16px;
    }
  }
  .resetButton {
    padding: 0;
  }
  .cancelButton {
    min-width: auto;
    padding: 0;
    color: var(--mui-palette-primary-main);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}
