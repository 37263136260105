.group {
  .groupImage {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    aspect-ratio: 218/131;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 4px;
    min-height: 134px;
    margin-bottom: 8px;
  }
  .groupTitle {
    color: var(--mui-palette-black-black087);
    margin-bottom: 8px;
  }
  .groupCount {
    display: flex;
    gap: 24px;
    .countItem {
      display: flex;
      align-items: center;
      gap: 4px;
      img {
        width: 18px;
        height: 18px;
      }
      p {
        color: var(--mui-palette-black-black058);
      }
    }
  }
  .memberList {
    padding: 10px 8px;
    :global(.MuiAvatarGroup-avatar) {
      width: 21px;
      height: 21px;
    }
    &.maxMember {
      :global(.MuiAvatarGroup-avatar) {
        &:nth-child(1) {
          overflow: visible;
          white-space: nowrap;
          background-color: #616161;
          font-size: 12px;
        }
      }
    }
  }
}
