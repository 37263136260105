.submitBtn {
  color: var(--mui-palette-black-black026);
  &.valid {
    color: var(--mui-palette-green-accent);
  }
}
.formContainer {
  padding: 16px;
  background-color: var(--mui-palette-white-opacity100);
  .toggleButtonWrapper {
    padding: 8px 16px;
    background-color: var(--mui-palette-customGrey-natural);
    border-radius: 10px;
    margin-bottom: 16px;
  }
  textarea {
    resize: none;
    width: 100%;
    font-size: 14px;
    margin: 0 !important;
    // padding: 8px 0px;
    border-radius: 5px;
    border: none;
    ::placeholder {
      color: var(--mui-palette-black-opacity26);
    }
    &:focus {
      border: none;
      outline: none;
    }
  }
  .wordCount {
    text-align: right;
    color: var(--mui-palette-black-opacity26);
    &.exceed {
      color: var(--mui-palette-error-main);
    }
  }
  .partnerTag {
    color: var(--mui-palette-green-accent);
  }
}
.postTypeContainer {
  padding: 8px 16px;
  display: flex;
  column-gap: 20px;
  & input[type='file'] {
    position: absolute;
    padding: 16px;
    width: 30px;
    height: 30px;
    visibility: hidden;
  }
  img {
    width: 30px;
  }
  .expandIcon {
    margin-left: auto;
    margin-right: 0px;
  }
}
.drawerHeader {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-top: 1px solid var(--mui-palette-black-opacity06);
  img {
    width: 24px;
  }
}
.suggestedWordContainer {
  // position: fixed;
  // left: 16px;
  position: absolute;
  top: 0;
  left: 0;
  height: 260px;
  // margin: 0px 16px;
  width: 100%;
  overflow: auto;
  border-top: 1px solid var(--mui-palette-black-opacity06);
  background-color: white;
  z-index: 1000;
  &.loading {
    margin-top: 10px;
    position: relative;
  }
  .suggestedWord {
    padding: 8px 0px;
  }
}

.listWrapper {
  &:global(.MuiList-root) {
    padding: 0;
  }
  .UploadFileFieldWrapper {
    :global(#attachments) {
      :global(.fileLabel) {
        display: flex !important;
        column-gap: 12px;
        padding: 16px !important;
        align-items: center;
        justify-content: flex-start !important;
        width: 100%;
        flex-direction: row;
      }
    }
  }
}

.drawerWrapper {
  & :global(.MuiDrawer-paper) {
    border-top: none;
    max-height: 50%;
  }
}

.UrlInputField {
  position: relative;
  & :global(.MuiInput-root) {
    &:before {
      border-bottom: 1px solid var(--mui-palette-customGrey-ceramic);
    }
  }
}

.EventSearchWrapper {
  position: fixed;
  z-index: var(--mui-zIndex-modal);
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: var(--mui-palette-white-opacity100);

  #header {
    padding: 0 16px;
    background-color: var(--mui-palette-white-opacity100);
    color: var(--mui-palette-black-opacity100);
    box-shadow: none;
    border-bottom: 1px solid var(--mui-palette-customGrey-natural);
    .title {
      padding: 12px 0;
    }
  }

  #backButton {
    padding: 11px 0;
    img {
      width: 28px;
    }
  }
}

.eventResultCard {
  position: relative;
  padding: 16px 0px;
  gap: 16px;
  .eventResultImage {
    width: 122px;
    height: 72px;
    border-radius: 3px;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .eventResultContent {
    .eventResultCategory {
      width: fit-content;
    }
    .eventResultTitle {
    }
    .eventResultDate {
      color: #6b6b6b;
      li {
        font-size: 12px;
        line-height: 16px;
        &[aria-hidden='true'] {
          margin: 0 4px;
        }
      }
    }
    .eventResultLocation {
      gap: 4px;
      color: #6b6b6b;
      img {
        width: 14px;
      }
    }
  }
  .deleteEventBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    img {
      width: 24px;
    }
  }
}

.mentions {
  font-family: 'SoDo Sans', 'Open Sans', sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  color: var(--mui-palette-black-black087);
  textarea {
    &::placeholder {
      color: var(--mui-palette-black-black026);
    }
  }
}
button.saveButton {
  min-width: auto;
  width: auto;
  padding: 0;
}
:global(#formContainer) {
  div[class*='__suggestions'] {
    top: 100% !important;
    left: 0 !important;
    width: 100% !important;
  }
}
