.autoCompleteContainer {
  height: 100%;
  overflow: hidden;
  .autoCompleteDropdown {
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow: auto;
  }
}

.optionsList {
  padding: 0;
  .optionItem {
    padding: 16px;
    .optionName {
      margin: 0;
    }
    .optionRadio,
    .optionCheckbox {
      padding: 0;
      margin-right: 0;
    }
  }
  .optionOtherField {
    width: 100%;
    padding: 8px 16px;
  }
  &:not(.optionsList__multiple) .optionItem__active {
    background-color: var(--mui-palette-green-accent);
    p:first-child {
      color: var(--mui-palette-white-opacity100)
    }
  }
}

.removeBtn {
  img {
    width: 18px;
    height: 18px;
    display: block;
  }
}
