.listWrapper{
   &:global(.MuiList-root){
      padding: 0;
   }
}
.listItemWrapper {
   &:global(.MuiListItem-root){
      display: flex;
      column-gap: 12px;
      padding: 16px;
      align-items: center;
   }
   .endIcon {
      margin-left: auto;
      margin-right: 0;
   }
}

