.groupContainer {
  background-color: #fff;
  padding-bottom: 65px;
}
.groupList {
  :global(.groupItemCard) {
    padding: 16px 0;
    & + :global(.groupItemCard) {
      padding-top: 0;
    }
  }
  :global(.countItem) {
    p {
      font-size: 14px;
    }
  }
}
.formContainer {
  background-color: #fff;
  .formWrapper {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .formField {
    display: block;
  }
}
.pageTitle {
  font-size: 20px;
  font-weight: 600;
  color: var(--mui-palette-black-black087);
  text-transform: capitalize;
}
.detailPage {
  background-color: #fff;
  overflow: auto;
  :global(.headerTitle) {
    overflow: hidden;
    :global(.text) {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.groupDetailInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  .groupCount {
    display: flex;
    flex: 0 0 auto;
    gap: 24px;
    .countItem {
      display: flex;
      align-items: center;
      gap: 4px;
      img {
        width: 18px;
        height: 18px;
      }
      p {
        color: var(--mui-palette-black-black058);
      }
    }
  }
}
.groupDetailContent {
  padding-bottom: 62px;
  position: relative;
  .backgroundContainer {
    min-height: 120px;
    .background {
      width: 100%;
    }
  }
  .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
    background-color: #fff;
    border-radius: 10px;
    width: calc(100% - 32px);
    padding: 16px;
    position: absolute;
    bottom: 22px;
    left: 50%;
    transform: translateX(-50%);
    .actionItem {
      width: 85px;
      text-align: center;
      a {
        text-decoration: none;
      }
      img {
        margin-bottom: 6px;
      }
      p {
        font-size: 12px;
        color: var(--mui-palette-black-black087);
      }
    }
  }
}
.about {
  padding: 16px;
  .aboutContent {
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    &.more {
      -webkit-line-clamp: unset;
    }
  }
}
.button {
  all: unset;
  color: var(--mui-palette-primary-main);
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.members {
  padding: 16px;
  border-top: 1px solid #f9f9f9;
  border-bottom: 1px solid #f9f9f9;
}
.gallery {
  padding: 16px;
}
.galleryContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  .galleryItem {
    aspect-ratio: 109/109;
    overflow: hidden;
    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .videoContainer {
      position: relative;
      aspect-ratio: 109/109;
      overflow: hidden;
      img {
        width: 40px;
        height: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.memberList {
  * {
    font-size: 16px;
  }
}
