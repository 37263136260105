.languageSelectField {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  max-width: 100%;
  padding: 8px;
  background: var(--mui-palette-customGrey-natural);
  border-radius: 5px;
  gap: 8px;
  img {
     display: block;
     width: 20px
  }
  &.disabled {
     p {
        color: var(--mui-palette-black-black026)
     }
  }
}
