.noRewardContentContainer {
   background-color: var(--mui-palette-white-opacity100);
   display: flex;
   flex-direction: column !important;
   overflow: auto;
   height: 100%;
   margin-top: -50px;
   .imageWrapper {
      width: 100px;
      margin: auto;
      img {
         width: 100px;
      }
   }
   
   .noRewardDescription {
      font-weight: 400;
      text-align: center;
      color: var(--mui-palette-black-black038);
   }
  
}
.contentWrapper {
   background-color: var(--mui-palette-white-opacity100);
}
.contentContainer {
   padding: 0px 16px;

   .rewardItemWrapper {
      align-items: center;
      padding: 16px 0px;
      color: var(--mui-palette-black-black087);
      .rewardImageWrapper {
         min-width: 103px;
         height: 58px;
         justify-content: center;
         align-items: center;
         img {
            max-width: 100%;
            max-height: 100%;
         }
      }
      p {
         overflow: hidden;
         text-overflow: ellipsis;
         display: -webkit-box;
         -webkit-line-clamp: 2;
         -webkit-box-orient: vertical;
         overflow-wrap: break-word;
      }
      
      .caption {
         color: var(--mui-palette-black-black058);

      }

      .statusTag {
         border-radius: 10px;
         padding: 4px 6px;
         margin-left: auto;
         margin-right: 0px;
         font-size: 10px;
         font-weight: 400;
         line-height: 13px;
         min-width: max-content;

         &:global(.onTheWay) {
            color: var(--mui-palette-green-apron);
            border: 1px solid var(--mui-palette-green-apron);
         }
         &:global(.pending) {
            color: var(--mui-palette-warning-main);
            border: 1px solid var(--mui-palette-warning-main);
         }
         &:global(.collected) {
            color: var(--mui-palette-green-starbucks);
            border: 1px solid var(--mui-palette-green-starbucks);
         }
        
      }
   }
}
