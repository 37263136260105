.listWrapper{
   &:global(.MuiList-root){
      padding: 0;
   }
}
.listItemWrapper {
   display: flex!important;
   column-gap: 12px;
   padding: 16px!important;
   align-items: center;
   justify-content: flex-start!important;
   &:global(.small){
      padding: 13px 16px!important;
   }
   .description {
      color: var(--mui-palette-black-black038);
      line-height: 14.4px;
      margin-top: 4px;
   }
   .endIcon {
      margin-left: auto;
      margin-right: 0;
   }
}

