.optionsList {
  padding: 0;
  .optionItem {
    padding: 16px;
    justify-content: space-between;
    .optionName {
      margin: 0;
      & :global(.desc){
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin-top: 4px;
        overflow: hidden;
        max-width: 260px;
      }
    }
    .optionRadio,
    .optionCheckbox {
      padding: 0;
      margin-right: 0;
    }
    &:global(.optionItem__reverse){
      justify-content: flex-end;
      flex-direction: row-reverse;
    }
    &:global(.optionItem__arrow){
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 16px;
          transform: translate(0, -50%);
          width: 24px;
          height: 24px;
        }
    }
    &:global(.optionItem__disabled){
      opacity: 0.5;
      pointer-events: none;
    }
  }
  .optionOtherField {
    width: 100%;
    padding: 8px 16px;
  }
}