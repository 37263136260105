.contentContainer {
   background-color: var(--mui-palette-white-opacity100);
}

.noResultsContainer {
   background-color: var(--mui-palette-white-opacity100);
   display: flex;
   flex-direction: column !important;
   overflow: auto;
   height: 100%;
   margin-top: -50px;
}