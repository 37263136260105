.noticeBox {
   padding: 8px;
   margin-right: 16px;
   align-items: center;

   &:global(.MuiPaper-root){
      padding: 8px;
   }
   & :global(.MuiAlert-icon){
      margin-right: 4px;
   }
   div {
      padding: 0;
   }

   &:global(.info) {
      background-color: var(--mui-palette-black-opacity12);
      color: var(--mui-palette-black-opacity87);
   }

   &.fixed{
      position: fixed;
      bottom: 22px;
   }
}