.filterHeader {
  padding: 16px;
  background-color: var(--mui-palette-customGrey-natural);
  color: var(--mui-palette-black-black087);
}

.contentContainer {
  //   padding: 0px 16px;
  height: 100%;
  background-color: var(--mui-palette-white-opacity100);

  .campaignItemWrapper {
    justify-content: space-between;
    align-items: center;
    padding: 16px 0px;
    margin: 0px 16px;
    border-bottom: 1px solid #f0f0f0;
    color: var(--mui-palette-black-black087);

    .dateTime {
      color: var(--mui-palette-black-black058);
    }

    .points {
      font-weight: 600;
      flex: 0 0 70px;
      text-align: right;
      span {
        font-weight: 400;
        color: var(--mui-palette-black-black038);
      }
    }
  }
}
.noPostContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: var(--mui-palette-white-opacity100);
  text-align: center;
  padding: 0 25px;
  img {
    margin-bottom: 16px;
  }
  h3 {
    margin-bottom: 8px;
  }
  p {
    color: var(--mui-palette-black-opacity38);
    height: 63px;
    margin-bottom: 50%;
  }
}
