.container {
  display: flex;
  flex-direction: column !important;
}
.banner {
  position: relative;
  min-height: 230px;
  .bannerBg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .bannerInfo {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .bannerIcon {
      margin-bottom: 17px;
      padding-left: 8px;
      font-size: 20px;
      font-weight: 600;
      color: #ffffff;
      text-align: left;
      width: 100%;
    }
    .bannerCaption {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      text-align: justify;
      color: #ffffff;
    }
  }
}

.content {
  padding: 16px;
  background-color: #ffffff;
  flex: auto;
  position: relative;
  min-height: 220px;
  padding-bottom: 80px;
}
.formField {
  textarea::placeholder {
    color: var(--mui-palette-black-black087);
    opacity: 0.4;
  }
}
.formCaption {
  color: var(--mui-palette-black-black038);
  font-size: 12px;
  font-weight: 400;
}
.formAction {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px;
}
.doneContainer {
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .doneContent {
    padding: 32px;
    text-align: center;
  }
  .doneCaption {
    h2 {
      font-size: 20px;
      font-weight: 600;
    }
    p {
      color: var(--mui-palette-black-black038);
      font-size: 14px;
    }
  }
}
.doneAction {
  padding: 16px;
}
