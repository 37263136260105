.contentContainer {
  background-color: #ffffff;
  min-height: calc(100vh - 51px);
  overflow: auto;
  padding: 0 16px;
  padding-bottom: 120px;
}
.item {
  cursor: pointer;
  display: flex;
  padding: 16px 0;
  &.groupItem {
    & + .groupItem {
      padding-top: 12px;
    }
    .itemImage {
      aspect-ratio: 122 / 72;
      width: 122px;
      flex: 0 0 auto;
    }
  }
  .itemImage {
    aspect-ratio: 103 / 58;
    flex: 0 0 auto;
    // width: 103px;
    width: 45%;
    max-width: 250px;
    margin-right: 12px;
    border-radius: 5px;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .itemContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: auto;
    .itemTitle {
      color: var(--mui-palette-black-black087);
      font-size: 14px;
      margin-bottom: 8px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.date {
  color: var(--mui-palette-black-black038);
  font-size: 12px;
}

.detail {
  .detailImage {
    display: none;
    img {
      width: 100%;
    }
  }
  .detailContent {
    padding: 16px;
    .detailTitle {
      font-size: 20px;
      font-weight: 600;
      color: #000;
      margin-bottom: 8px;
    }
    .date {
      margin-bottom: 16px;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .detailText {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    figure {
      margin: 0;
    }
  }
}

.listContainer {
  :global(.MuiAccordion-root) {
    all: unset;
    position: relative;
    &::after {
      all: unset;
      content: '';
      position: absolute;
      bottom: 1px;
      left: 0px;
      display: block;
      width: 100%;
      height: 1px;
      background-color: #edebe9;
    }
  }
  :global(.MuiAccordionSummary-content) {
    align-items: center;
    justify-content: space-between;
    span {
      flex: 0 0 80%;
    }
    img {
      width: 24px;
      transition: all 0.3s ease-in-out;
    }
  }
  :global(.MuiAccordionSummary-content.Mui-expanded) {
    margin-top: 16px;
    margin-bottom: 12px;
    font-size: 16px;
    color: var(--mui-palette-black-black087);
    img {
      transform: rotate(-180deg);
    }
  }
  :global(.MuiAccordionDetails-root) {
    padding-top: 0;
    font-size: 14px;
    color: var(--mui-palette-black-black058);
    img {
      max-width: 100%;
      height: auto;
    }
  }
  :global(.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded) {
    min-height: 0;
  }
}
.categoryLabel {
  background-color: var(--mui-palette-green-starbucks);
  color: #fff;
  display: table;
  padding: 3px 6px;
  font-size: 12px;
  border-radius: 4px;
  margin-bottom: 4px;
}
.groupInfo {
  display: flex;
  gap: 24px;
  .date {
    min-width: 90px;
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
.groupEdit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  .img {
    line-height: 0;
  }
  button {
    all: unset;
    font-size: 12px;
    color: var(--mui-palette-green-accent);
    font-weight: 600;
  }
  image {
    width: 24px;
    height: 24px;
  }
}
.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 200px);
  .noDataContainer {
    text-align: center;
    p {
      font-size: 14px;
      line-height: 16.8px;
      color: var(--mui-palette-black-black038);
    }
  }
}
