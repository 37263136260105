.postCommentContainer {
   padding-bottom: 100px;
   .commentField {
      padding: 12px 16px;
      display: flex;
      // align-items: center; //when not focus
      align-items: start;
      textarea {
         resize: none;
         width: 100%;
         font-size: 14px;
         padding: 8px;
         border-radius: 5px;
         border-color: var(--mui-palette-black-opacity06);
         caret-color: var(--mui-palette-green-starbucks);
         ::placeholder {
            color: var(--mui-palette-black-opacity26);
         }
         &:focus {
           outline: none;
         }
      }
      .postButton {
         font-size: 14px;
         line-height: 17px;
         text-transform: none;
         padding: 0px 0px 0px 8px;
         align-self: flex-start;
         width: fit-content;
         min-width: 40px;
         margin-top: 10px;
      }
   }
   
   
   .commentContainer {
      &:before {
         content: "";
         position: absolute;
         left: 0;
         top: 0;
         display: block;
         height: 8px;
         width: 100vw;
         background-color: var( --mui-palette-customGrey-natural);
      }
      background-color: var(--mui-palette-white-opacity100);
      display: table;
      position: relative;
      padding: 24px 16px 16px 16px;
   
      .row{
         display: table-row;
      }  
      .cell, .avatarWrapper, .commentWrapper{
         display: table-cell;
         vertical-align: top;
         padding-right: 4px;
         padding-left: 4px;
         padding-top: 4px;
      }
      .avatarWrapper {
         width: 32px;
      }
      .commentWrapper {
         width: 100%;
         .contentContainer {
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            .title {
               color: var(--mui-palette-black-opacity100);
            }
            .details {
               color: var(--mui-palette-black-opacity58);
            }
            .timestamp {
               display: flex;
               column-gap: 4px;
               color: var(--mui-palette-black-opacity38);
            }
         }
      }
     
   }
}