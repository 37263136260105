.menuItemContainer {
  width: 100%;
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  background-color: var(--mui-palette-white-opacity100);
  z-index: 1000;
  max-width: 1024px;
  .menuItem {
    border-top: 1px solid var(--mui-palette-black-opacity06);
    flex: 1 1 0;
    padding: 10px 5px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -6px;
    p {
      color: #9e9e9e;
    }
    &.active {
      // border-top: 2px solid green;
      border-top: 2px solid var(--mui-palette-green-starbucks);
      padding-top: 9px;
      p {
        color: var(--mui-palette-green-starbucks);
      }
    }
    img {
      margin-bottom: 2px;
    }
  }
}

.couponIcon {
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
}
