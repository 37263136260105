.contentContainer {
  background-color: #fff;
  height: 100%;
  overflow: auto;
  // overflow: auto;
  padding-bottom: 64px;
}
.tabWrapper {
  padding: 0 16px;
}

.transferredList {
  all: unset;
  width: 100%;
  li {
    padding: 16px;
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--mui-palette-black-black087);
    }
    .text {
      font-size: 14px;
      color: var(--mui-palette-black-black087);
    }
    .info {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    .infoContainer {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .copyLink button {
      all: unset;
      font-size: 12px;
      font-weight: 600;
      color: var(--mui-palette-green-accent);
      cursor: pointer;
      white-space: nowrap;
    }
  }
}
.tips {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding: 16px;
  font-size: 14px;
  text-align: center;
  color: var(--mui-palette-black-black087);
  box-shadow: 0px 0px 4px #e0e0e0;
}
.receivedList {
  all: unset;
  width: 100%;
  padding: 16px;
  display: block;
  box-sizing: border-box;
  li {
    display: flex;
    padding: 16px 0;
    gap: 24px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;
    .date {
      font-size: 14px;
      color: var(--mui-palette-black-black087);
    }
    .text {
      font-size: 14px;
      color: var(--mui-palette-green-starbucks);
    }
  }
}
