
.root {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: var(--mui-palette-black-opacity70);
    color: var(--mui-palette-white-opacity100);
    z-index: 1100;
}

.header {
    display: flex;
    padding: 12px 16px;
    gap: 10px;
    position: relative;
    z-index: 10;
    .backButton {
        padding: 0;
    }
}

.content {
    padding: 24px;
}

.container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    gap: 24px;
}
