
.contentContainer {
   background-color: var(--mui-palette-white-opacity100);
   overflow: auto;
   height: 100%;
}
.sectionContainer {
   padding: 16px;
   overflow: hidden;
   .sectionHeader {
      font-weight: 700;
      text-transform: uppercase;
      color: var(--mui-palette-green-house);
      letter-spacing: 0.1em;
   }
   .sectionBtn{
      margin-left: auto;
      margin-right: 0;
      color: var(--mui-palette-green-starbucks);
      font-weight: 600;
   }
   .surveyItemContainer{
      align-items: center;

      .surveyItemWrapper {
         padding: 16px 0px;
         align-items: center;
         .surveyItemTitle {
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;  
            overflow: hidden;
            line-height: 18.13px;
         }
         .surveyItemDate{
            color: var(--mui-palette-black-black038);
         }

         img {
            max-width: 70px;
            border-radius: 5px;
         }
        
      }
      .copyLinkBtn {
         color: var(--mui-palette-green-accent);
         min-width: max-content;
         margin-left: auto;
         margin-right: 0;
      }
   }
  
   .templateWrapper {
      img {
         border-radius: 5px;
      }
   }

}


.popupTitle {
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.tabWrapper {

}

.alertMsg {
   display: flex;
   justify-content: space-between;
   align-items: center;
   .alertBtn {
      font-weight: 600;
      min-width: max-content;
   }
}

