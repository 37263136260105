.headerContainer {
  position: relative;
  line-height: 0px;
  .backBtn {
    position: absolute;
    top: 10px;
    left: 16px;
  }
  img {
    max-width: 100%;
  }
}
.pageContainer {
  height: 100%;
  background-color: var(--mui-palette-white-opacity100);
  &:global(#thankyouPage),
  &:global(#errorPage) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 32px;
    text-align: center;
    img {
      margin-bottom: 16px;
    }
    h3 {
      margin-bottom: 8px;
      color: var(--mui-palette-black-black087);
    }
    p {
      color: var(--mui-palette-black-black038);
    }
    .buttonContainer {
      box-shadow: none;
    }
  }
}
.surveyDetailContainer {
  padding: 20px 16px;
  background-color: var(--mui-palette-white-opacity100);
  .title {
    margin-bottom: 8px;
    color: var(--mui-palette-black-black087);
  }
  .date {
    margin-bottom: 24px;
    line-height: 14.4px;
    color: var(--mui-palette-black-black038);
  }
  .content {
    margin-bottom: 12px;
    color: var(--mui-palette-black-black058);
  }
  .remarks {
    line-height: 14.4px;
    color: var(--mui-palette-black-black038);
  }
}

.questionImage {
  max-width: 100%;
  width: 100%;
}
.contentContainer {
  background-color: var(--mui-palette-white-opacity100);
  padding: 24px 16px;
  .numOfQuestion {
    color: var(--mui-palette-green-starbucks);
    line-height: 18.13px;
    margin-bottom: 12px;
  }
  .required {
    color: var(--mui-palette-error-main);
  }
  .remarks {
    color: var(--mui-palette-black-black038);
    margin-top: 12px;
    &.error {
      color: var(--mui-palette-error-main);
    }
  }
  .optionContainer {
    margin-top: 16px;
    & :global(.MuiListItemButton-root) {
      padding: 8px 0px;
      & :global(.MuiRadio-root) {
        padding: 0px 18px 0px 0px;
      }
    }
    & :global(.MuiListItem-root) {
      gap: 10px;
      & > :global(.MuiButtonBase-root) {
        flex: 0 auto;
        width: 100%;
        & .inputWrapper {
          position: relative;
          width: 100%;
          & .inputField {
            & ~ .lengthCounter {
              position: absolute;
              right: 0px;
              bottom: -20px;
            }
          }
        }
      }
    }
  }
  .scaleItemContainer {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    padding: 16px;
    row-gap: 32px;
    justify-content: space-between;
    .scaleItem {
      width: 45px;
      height: 45px;
      color: var(--mui-palette-black-black087);
      border: 1px solid #edebe9;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      &:global(.active) {
        border: none;
        background-color: var(--mui-palette-green-light);
        color: var(--mui-palette-green-starbucks);
      }
    }
  }
  .ratingStarContainer {
    padding: 16px;
  }
}
.buttonContainer {
  width: 100%;
  position: fixed;
  padding: 24px 16px;
  bottom: 0px;
  background-color: var(--mui-palette-white-opacity100);
  box-shadow: 0px -2px 15px 0px #0000000d;
  align-items: center;
  max-width: 1024px;
  .confirmNotice {
    align-items: center;
    p {
      line-height: 15.54px;
      color: var(--mui-palette-green-starbucks);
    }
  }
}

.inputWrapper {
  & .inputField {
    all: unset;
    border-bottom: 1px solid #2121214d;
    width: 100%;
    &::placeholder {
      font-size: 14px;
      font-weight: 400;
      line-height: 18.2px;
      text-align: start;
    }
  }
}

.lengthCounter {
  width: max-content;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: right;
  color: #bdbdbd;
}

.multipleChoiceWithImageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f9f9f9;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  padding: 10px;
  & > img {
    display: block;
    border-radius: 5px;
    max-height: 100%;
    min-height: 150px;
    max-width: 100%;
    object-fit: cover;
    margin-bottom: 12px;
  }
  & > div {
    font-size: 16px;
    font-weight: 400;
    line-height: 20.72px;
  }
  &.check {
    background: #d4e9e2;
    border: 1px solid #006241;
  }
}

.optionContainerWithImage {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  gap: 10px;
  margin-top: 16px;
  padding-bottom: 100px;
  & :global(.MuiListItemButton-root) {
    padding: 8px 0px;
    & :global(.MuiRadio-root) {
      padding: 0px 18px 0px 0px;
    }
  }
}

.questionWrapper {
  overflow: auto;
  padding-bottom: 100px;
}
