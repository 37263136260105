.postContainer {
   display: flex;
   flex-direction: column !important;
   overflow: auto;
   height: 100%;
}
.noPostContainer {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 100%;
   background-color: var(--mui-palette-white-opacity100);
   
   img {
      margin-bottom: 16px;
   }
   h3 {
      margin-bottom: 8px;
   }
   p {
      color: var(--mui-palette-black-opacity38);
      height: 63px;
      margin-bottom: 50%;
   }
}

.scheduledPostTitle {
   padding: 16px;
   background-color: var(--mui-palette-white-opacity100);
}
.reportDetailPopup{
   overflow: hidden;
   position: relative;
   & :global(.reportDetailContainer){
      height: 100%;
   }
   & :global(.reportList){
      background-color: var(--mui-palette-customGrey-natural);
      padding-top: 1px;
   }
   & :global(.MuiListItem-root){
      margin-bottom: 8px;
      &:last-child{
         margin:0;
      }
   }
   & :global(.reportDetailCard){
      background-color: white;
      padding: 16px;
      width: 100%;
      & :global(.cardTop){
         margin-bottom: 6px;
         & span{
            font-size: 16px;
            color: var(--mui-palette-black-black087);
         }
      }
      & :global(.cardDetails){
         font-size: 14px;
         line-height: 18px;
      }
      & :global(.cardTitle){
         color: var(--mui-palette-black-black038);
         margin-bottom: 6px;
      }
      & :global(.cardContent){
         color: var(--mui-palette-black-black087);
         text-align: right;
         max-width: 200px;
         margin-bottom: 6px;
         &:global(.lineClamp){
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
         }
      }
   }
   
}
