.toolbarWrapper {
   position: absolute;
   right: 16px;
    .toolbarItemContainer {
        display: flex;
        flex-direction: row;
        column-gap: 18px;

        .toolbarItem {
            //  margin-left:18px;
            //  &:first-child {
            //     margin-left: 0px;
            //  }
             &.active{
                padding-top: 9px;
                p{
                    color: var(--mui-palette-green-starbucks); 
                }
             }
             img{
                margin-bottom: 8px;
             }
             
       } 
    }
}

.popupTitle{
   display: flex;
   align-items: center;
   justify-content: space-between;
}
