.headerSection {
   width: 100%;
   position: relative;
   z-index: 1;
   box-sizing: border-box;
   background: var(--mui-palette-gradient-green); 
}

.user {
   &Section {
      padding: 16px 8px;
      color: var(--mui-palette-white-opacity100);
      & > div {
         padding: 8px;
      }

      .userAvatar {
         width: 56px;
         height: 56px;
         border: 1px solid var(--mui-palette-white-opacity100);
         border-radius: 100px;
         img {
            width: 100%;
            height: 100%;
         }
      }
   }
   
   &Info {
      color: inherit;
      p {
         padding-top: 4px;
         color: inherit;
      }
   }
   &Arrow {
      button {
         padding: 0;
      }
   }
}

.score {
   padding: 0 16px;
   position: relative;
   &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 50%;
      background-color: var(--mui-palette-customGrey-natural);
   }
   &Box {
      position: relative;
      padding: 16px;
      background: var(--mui-palette-background-default);
      border-radius: 10px;
      .img {
         flex-basis: 40px;
         img {
            display: block;
            width: 40px;
         }
      }
   }
   &List {
      & > div {
         padding-left: 24px;
         &:not(:first-child) {
            .scoreItem {
               border-left: 1px solid var(--mui-palette-customGrey-ceramic);
               padding-left: 16px;
            }
         }
      }
   }
   &Item {
      width: 100%;
      text-align: left;
      h3 {
         padding-bottom: 8px;
      }
      img {
         display: block;
         width: 16px
      }
   }
}

.container {
   padding-bottom: 80px;
   background: var(--mui-palette-background-default);
   overflow: auto;
   .content {
      padding: 16px;
   }
}

.section {
   width: 100%;
   border-top: 8px solid var(--mui-palette-customGrey-natural);
   &:first-child {
      border-top-width: 10px;
   }
   & &ShortCutList {
      padding: 8px;
   }
   & &ShortCut {
      padding: 8px;
      text-align: center;
      img {
         display: block;
         margin: 0 auto 8px;
      }
   }
   & &Title {
      padding: 16px;
      padding-bottom: 8px;
      color: var(--mui-palette-green-house);
   }
   & &List {
      width: 100%;
   }
   & &Item {
      padding: 16px;
   }
   & &Label {
      display: flex;
      align-items: center;
      justify-content: space-between;
   }
   & &Arrow {
      display: block;
      width: 24px;
   }
}

.closeButton {
   padding: 0;
   img {
      display: block;
      width: 24px;
   }
}

.optionRadio {
   padding: 0;
}

.form {
   &Container {
      padding: 16px;
      background: var(--mui-palette-background-default);
      overflow: auto;
   }
   &Field {
      &:not(:last-child) {
         padding-bottom: 12px;
      }
      &Divider {
         display: flex;
         border-bottom: 1px solid var(--mui-palette-customGrey-ceramic);
         padding-left: 8px;
         hr {
            height: 20px;
            margin: auto 0;
            border-color: var(--mui-palette-black-black026);
         }
         &[data-error=true] {
            border-bottom-color: var(--mui-palette-error-main);
         }
      }
   }
   &Button {
      padding-top: 12px;
      margin-top: auto;
   }
}
