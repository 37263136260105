.container {
  overflow-x: hidden;
  padding-bottom: 80px;
  :global(.infinite-scroll-component) {
    overflow: visible !important;
  }
}
.couponContainer {
  position: relative;
  // right: -32px;
  width: 100%;
}
.checkbox {
  width: 100%;
  + .checkbox {
    margin-top: 16px;
  }
  input {
    display: none;
    &:checked + label {
      &::before {
        background-color: #006241;
        border: 1px solid #006241;
        background-image: url('~/public/assets/images/check_white.svg');
      }
    }
  }
  label {
    position: relative;
    display: block;
    padding-left: 32px;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #bdbdbd;
      background-position: center center;
    }
  }
}
.transferAction {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 16px;
  justify-content: flex-end;
  background-color: #fff;
  box-shadow: 0px -2px 15px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  transition: all 0.3s ease;
  button {
    width: auto;
  }
}
.popupContent {
  padding: 16px;
  > p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: var(--mui-palette-black-black038);
    margin-bottom: 24px;
  }
  .remarksText {
    font-size: 14px;
    font-weight: 600;
    color: var(--mui-palette-black-black058);
    margin-bottom: 12px;
    width: 100%;
  }
  .formField {
    width: 100%;
    textarea::placeholder {
      color: var(--mui-palette-black-black087);
      opacity: 0.4;
    }
  }
  .popupAction {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
    padding: 24px 0;
  }
}
