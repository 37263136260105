.listWrapper{
  padding: 0;
  .filterOption{
     padding: 16px;
  }
}
.filterTagContainer{
  width: 100%;
  padding: 24px 16px;
  flex-flow: wrap;
  gap: 8px;
  & :global(.MuiChip-root){
     background-color: var(--mui-palette-green-light);
     color: var(--mui-palette-green-starbucks);
  }
}

.buttonContainer{
  width: 100%;
  // position: sticky;
  padding: 24px 16px;
  // bottom: 0px;
  background-color: var(--mui-palette-white-opacity100);
  box-shadow: 0px -2px 15px 0px var(--mui-palette-black-opacity05); 
  // margin-top: auto;
}

.filterOptionName {
  word-break: break-all;
}

.filterHeader {
  gap: 10px;
  .button {
    padding: 0;
    min-width: unset;
  }
}

.filterContainer {
  height: 100%;
}

.filterList {
  overflow-y: auto;
  overflow-x: hidden;
}