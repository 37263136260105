.uploadFileSection {
  position: relative;
  input {
    opacity: 0;
    position: absolute;
    z-index: -9999;
  }
  .uploadImage {
    width: 100%;
    height: 100%;
    padding: 16px;
    position: relative;
    gap: 4px;
    color: var(--mui-palette-white-opacity100);
    background-color: var(--mui-palette-primary-main);
    &[data-error="true"] {
      border: 1px solid var(--mui-palette-error-main);
    }
    .uploadImageBg {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &HelperText {
      padding: 4px 16px 0 16px;
      &[data-error="true"] {
        color: var(--mui-palette-error-main);
      }
    }
    div {
      position: relative;
    }
    p {
      color: var(--mui-palette-white-opacity100);
    }
  }
  .previewImage {
    width: 100%;
    height: 100%;
    position: relative;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .editButton {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 32px;
      height: 32px;
      padding: 0;
      background: var(--mui-palette-green-accentOpacity50);
      backdrop-filter: blur(3px);
      border-radius: 100%;
    }
  }
  .previewAvatar {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    border: 3px solid var(--mui-palette-white-opacity100);
  }
  .questionImage {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    &:not(.uploaded) {
      background-color: var(--mui-palette-customGrey-natural);
    }
    .img {
      aspect-ratio: 107/59;
      max-width: 107px;
    }
    img {
      display: block;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
    .label {
      color: var(--mui-palette-black-black038);
    }
    .helperText {
      color: var(--mui-palette-black-black026);
    }
  }
  .answerImage {
    width: 60px;
    height: auto;
    border-radius: 4px;
    background: #f0f0f0;
    margin-right: 8px;
    position: relative;
    .uploadedImg {
      display: block;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
    .editMsg {
      display: block;
      position: absolute;
      bottom: 0;
      left: -2px;
      background: #0000004d;
      width: 100%;
      color: #fff;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
    }
  }
}

.closeButton {
  padding: 0;
  img {
    width: 24px;
    height: 24px;
  }
}

.editOptionList {
  padding: 0;
  .editOption {
    padding: 16px;
    gap: 12px;
  }
}

.multipleFileItem {
  width: 100%;
  padding: 4px;
  gap: 4px;
  background-color: var(--mui-palette-customGrey-natural);
  border-radius: 5px;
  box-sizing: border-box;
  .multipleFileIcon {
    width: 56px;
    height: 56px;
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }
  .multipleFileName {
    overflow: hidden;
    color: var(--mui-palette-black-black058);
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow-wrap: break-word;
    }
  }
  .multipleFileRemove {
  }
}

.removeButton {
  padding: 0;
  img {
    display: block;
    width: 16px;
    height: 16px;
  }
}

.cropper {
  &Container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    p {
      padding-top: 8px;
      color: var(--mui-palette-black-black026);
    }
  }
}
