.splashScreen {
  background-color: #006241;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.starbucksLogo {
  width: 100px;
  height: 100px;
  animation: fade-in 0.8s ease-in;
}
