.mediaPopup {
  & :global(.MuiPaper-root) {
    background-color: var(--mui-palette-black-opacity100);
  }
  & :global(.MuiDialogContent-root) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    overflow-x: hidden;
  }
  & :global(.MuiIconButton-root) {
    position: fixed;
    left: 16px;
    top: 11px;
  }
  // & :global(.swiper-slide) {
  //     align-items: center;
  //     display: flex;
  //  }

  button {
    z-index: 100;
  }
}
