.mediaItem {
  background: rgba(0, 0, 0, 0.2);
  justify-content: center;
}

.imgRemoveBtn {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
