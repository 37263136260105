.container {
  padding: 16px;
  background-color: #fff;
  align-items: flex-start;
  // overflow: auto;
}
.banner {
  background-image: url('~/public/assets/images/coupon_Img.svg');
  background-repeat: no-repeat;
  background-position: -16px -12px;
  background-size: 93px;
  background-color: var(--mui-palette-green-house);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  padding: 8px 16px;
  height: 44px;
  border-radius: 5px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
  margin-bottom: 16px;
  width: 100%;
  .bannerText {
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    width: 166px;
  }
  button {
    all: unset;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    padding: 7px 12px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    flex: 0 0 auto;
    color: var(--mui-palette-green-accent);
  }
}

.availableText {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 700;
  color: var(--mui-palette-green-house);
  letter-spacing: 1.4px;
  text-transform: uppercase;
  width: 100%;
}

.status {
  position: absolute;
  right: 0;
  top: 0;
  padding: 2px 8px;
  border-radius: 0px 4px;
  color: #fff;
  font-size: 12px;
  z-index: 1;
  &:global(.yellow) {
    background-color: var(--mui-palette-warning-main);
  }
  &:global(.grey) {
    background-color: var(--mui-palette-black-black038);
  }
}

.couponContainer {
  position: relative;
  width: 100%;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  overflow: hidden;
  + .couponContainer {
    margin-top: 16px;
  }
}

.coupon {
  padding: 12px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
  background-position: bottom;
  // height: 137px;
  // max-height: 146px;
  cursor: pointer;
  .logo {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 16px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .name {
    font-size: 18px;
    font-weight: 800;
    color: var(--mui-palette-green-house);
    // min-height: 38px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .denomination {
    font-size: 16px;
    font-weight: 400;
    color: var(--mui-palette-black-black078);
    height: 38px;
    strong {
      color: var(--mui-palette-green-house);
      font-size: 36px;
      font-weight: 600;
      margin-left: 4px;
      display: inline-block;
    }
  }
  .left {
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 16px;
    font-weight: 500;
    color: var(--mui-palette-black-black078);
  }
  .right {
    flex: 0 0 103px;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .top {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 12px;
    .logo {
      width: 30px;
      height: 30px;
      margin: 0;
    }
    .name {
      font-style: 16px;
      font-weight: 600;
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .codeContainer {
      font-size: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .denomination {
      font-weight: 600;
    }
  }
}

.couponDetail {
  .header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    button {
      all: unset;
      font-size: 14px;
      font-weight: 600;
      color: #fff;
    }
  }
  .detailContainer {
    padding: 16px;
    justify-content: center;
    min-width: 375px;
  }
  .detailWrapper {
    height: 524px;
    background-image: url('~/public/assets/images/Subtract.svg');
    background-position: center center;
    background-size: 106% 543px;
    width: 343px;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    position: relative;
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 23px;
      height: 22px;
      border-radius: 50%;
      top: 135px;
      z-index: 1;
    }
    &::before {
      left: -12px;
    }
    &::after {
      right: -12px;
    }
  }
  .qrCode {
    padding: 16px;
    width: 100%;
    .qrCodeText {
      font-size: 14px;
      font-weight: 600;
      color: var(--mui-palette-black-black078);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 100%;
    }
    .qrCodeContainer {
      width: 185px;
      height: 185px;
      margin: 0 auto;
      padding: 16px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .qrStatus {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 5px 10px;
        border-radius: 4px;
        background-color: var(--mui-palette-green-light);
        color: var(--mui-palette-green-starbucks);
        font-size: 12px;
        text-transform: capitalize;
      }
    }
  }
}
.tnc {
  padding: 24px 0px;
  color: #fff;
  width: 100%;
  .tncTitle {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .tncContent {
    font-size: 12px;
    white-space: pre-wrap;
  }
}
.pagination {
  color: var(--mui-palette-black-black038);
  font-size: 14px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
}
.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 50px);
  padding: 0 32px;
  text-align: center;
  img {
    margin-bottom: 16px;
  }
  .title {
    font-size: 20px;
    font-weight: 600;
    color: var(--mui-palette-black-black087);
    margin-bottom: 8px;
  }
  .text {
    font-size: 14px;
    color: var(--mui-palette-black-black078);
  }
}

.swiperContainer {
  height: auto!important;
  overflow-x: unset!important;
  
  background-color: var(--mui-palette-green-starbucks);
  display: flex;
  align-items: flex-start;
  :global(.swiper) {
    max-width: 375px;
    overflow: visible;
  }
}

.tools {
  display: flex;
  align-items: center;
  gap: 10px;
  button {
    all: unset;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    font-weight: 600;
    color: var(--mui-palette-black-black087);
    img {
      width: 24px !important;
      height: auto;
    }
  }
}
