.headerSection {
  width: 100%;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  background: var(--mui-palette-background-greenLinearGradient106deg);
  .actions {
    padding: 0 16px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 50%;
      background-color: var(--mui-palette-customGrey-natural);
    }
    &List {
      padding: 0 20px;
      position: relative;
      background: var(--mui-palette-white-opacity100);
      border-radius: 10px;
    }
    &Item {
      width: 100%;
      padding: 20px;
      position: relative;
      box-sizing: border-box;
      border: none;
      background-color: transparent;
      img {
        display: block;
        padding-bottom: 4px;
        margin: 0 auto;
      }
      p {
        &.active:after {
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: 6px;
          height: 6px;
          background-color: var(--mui-palette-green-apron);
          border-radius: 100%;
          margin-left: 4px;
        }
      }
    }
  }
}

.cardList {
  &Container {
    display: flex;
    flex-direction: column !important;
    overflow: auto;
    padding: 16px 0;
  }
  &Section {
    width: 100%;
    padding-bottom: 16px;
    overflow: hidden;
    flex: 0 0 auto;
    &Title {
      padding: 16px;
      p {
        font-weight: 700;
      }
      button {
        padding: 0;
      }
    }
    &Content {
      width: 100%;
      padding: 0 16px;
      // gap: 8px;
      // overflow-y: hidden;
      // overflow-x: auto;
    }
    & &Card {
      flex-basis: 96px;
      width: 96px;
      aspect-ratio: 96/160;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.sentAndReceivedContainer {
  display: flex;
  flex-direction: column !important;
  overflow: auto;
  background-color: var(--mui-palette-white-opacity100);
  & :global(.infinite-scroll-component__outerdiv) {
    width: 100%;
  }
}

.sent {
  &List {
    padding: 0 16px;
  }
  &Item {
    display: flex;
    padding: 16px 0;
    gap: 18px;
    border-bottom: 1px solid var(--mui-palette-customGrey-seashell);
    &RepliedMark {
      width: 8px;
      span {
        display: block;
        width: 8px;
        height: 8px;
        background: var(--mui-palette-green-apron);
        border-radius: 100%;
      }
    }
    &Image {
      width: 38px;
      &Skeleton {
        width: 100%;
        height: auto;
        aspect-ratio: 38 / 60;
      }
      img {
        display: block;
        width: 100%;
      }
    }
    &Info {
      width: 8px;
      &Date {
        color: var(--mui-palette-black-black058);
      }
      &RepliedMsg {
        color: var(--mui-palette-black-black038);
      }
      h5,
      p {
        word-break: break-all;
      }
    }
    &Status {
      width: 24px;
    }
  }
}

.received {
  &List {
    & :global(.infinite-scroll-component) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      & div {
        max-width: unset;
      }
    }
    padding: 16px;
    padding-left: 1px;
    padding-top: 1px;
    > div > div > div {
      padding-left: 15px;
      padding-top: 15px;
    }
  }
  &Item {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    &Image {
      width: 100%;
      aspect-ratio: 164 / 273;
      position: relative;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &.active {
        &:after {
          position: absolute;
          left: 0;
          bottom: 0;
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0) 34.38%,
              rgba(30, 57, 50, 0.4) 68.23%,
              rgba(30, 57, 50, 0.7) 87.5%
            ),
            no-repeat;
        }
      }
    }
    &Info {
      position: absolute;
      left: 12px;
      bottom: 16px;
      width: calc(100% - 24px);
      color: var(--mui-palette-white-opacity100);
      p:first-child {
        color: var(--mui-palette-white-opacity70);
      }
      p:last-child {
        padding-top: 4px;
      }
    }
  }
}

.detail {
  &Container {
    display: flex;
    flex-direction: column !important;
    overflow: auto;
    background: var(--mui-palette-background-greenLinearGradient128deg);
    .editButton {
      min-width: auto;
      padding: 0;
      color: var(--mui-palette-white-opacity100);
    }
  }
  &FailSection {
    padding-bottom: 8px;
    gap: 9px;
    color: var(--mui-palette-white-opacity100);
    img {
      display: block;
    }
  }
  &ResendButton:global(#resendCardButton) {
    padding: 7px 12px;
    border: 1px solid var(--mui-palette-white-opacity100);
    border-radius: 20px;
    color: var(--mui-palette-white-opacity100);
  }
  &Content {
    display: grid;
    padding: 0 16px;
    gap: 8px;
  }

  &Card {
    display: grid;
    width: 100%;
    padding: 32px 16px;
    background-color: var(--mui-palette-white-opacity100);
    border-radius: 10px;
    gap: 16px;
    text-align: center;
    &ToTarget {
      display: grid;
      gap: 4px;
    }
    &Info {
    }
    &Skeleton {
      margin-left: auto;
      margin-right: auto;
    }
    &Image {
      width: 51%;
      margin: 0 auto;
      position: relative;
      background-color: var(--mui-palette-white-opacity100);
      box-shadow: 0px 4px 10px var(--mui-palette-black-opacity20);
      border-radius: 10px;
      overflow: hidden;
      &Skeleton {
        width: 100%;
        aspect-ratio: 160 / 248;
      }
      img {
        display: block;
        width: 100%;
      }
    }
    &DefaultMsg {
      margin-top: -16px;
      padding: 16px;
      padding-top: 32px;
      background: var(--mui-palette-customGrey-natural);
      border-radius: 10px;
      p {
        color: var(--mui-palette-black-black058);
      }
    }
    &Description {
    }
    &FromTarget {
      display: grid;
      gap: 4px;
      .date {
        padding-top: 4px;
        color: var(--mui-palette-black-black058);
        img {
          vertical-align: sub;
          width: 16px;
          margin-right: 4px;
        }
      }
    }
  }

  &RepliedBox {
    width: 100%;
    background-color: var(--mui-palette-white-opacity100) !important;
    border-radius: 10px !important;
    box-shadow: none !important;
    background-image: none !important;
    .header {
      padding-bottom: 8px;
      & > div:first-child {
        margin-right: 8px;
      }
      .avatar {
        width: 20px;
        height: 20px;
      }
    }
    .content {
      display: grid;
      padding-top: 0;
      padding-bottom: 16px;
      gap: 4px;
      color: var(--mui-palette-black-black087);
      span {
        color: var(--mui-palette-black-black038);
      }
    }
  }

  &RepliedField {
    padding: 24px 16px;
    margin-top: 8px;
    background-color: var(--mui-palette-white-opacity100);
    border-radius: 10px;
    .avatar {
      width: 32px;
      height: 32px;
      margin-right: 10px;
    }
    .textareaField {
      width: 100%;
      padding: 8px;
      gap: 10px;
      border: 1px solid var(--mui-palette-customGrey-seashell);
      border-radius: 5px;
      color: var(--mui-palette-black-black026);
      &.error {
        border-color: var(--mui-palette-error-main);
        color: var(--mui-palette-error-main);
      }
      textarea {
        width: 100%;
        background-color: transparent;
        border: none;
        outline: none;
        resize: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: var(--mui-palette-black-black087);
      }
    }
    .submitButton {
      min-width: auto;
      padding: 0;
      margin-left: 8px;
    }
  }
}

.tabs {
  padding: 12px 16px;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--mui-palette-black-black026);
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: transparent;
  }
  &:global(.active) {
    color: var(--mui-palette-primary-main);
    &:after {
      background-color: var(--mui-palette-primary-main);
    }
  }
  &List {
    padding: 0 16px;
    background-color: var(--mui-palette-white-opacity100);
    overflow-y: hidden;
    overflow-x: auto;
  }
}

.section {
  &Container {
    width: 100%;
    padding-top: 16px;
  }
  &Title {
    padding: 0 10px;
    font-weight: 700;
  }
  &List {
    padding: 8px 16px 16px 8px;
    > div {
      padding-left: 8px;
      padding-top: 8px;
    }
  }
  &Item {
    &Skeleton {
      width: 100%;
      aspect-ratio: 109 / 180;
    }
  }
}

.send {
  &Container {
    display: flex;
    flex-direction: column !important;
    overflow: auto;
  }
  &FormSection {
    padding-top: 10px;
    padding-bottom: 6px;
    background-color: var(--mui-palette-white-opacity100);
    margin-bottom: 8px;
    box-shadow: 0px 1px 1px var(--mui-palette-black-opacity04);
    .formField {
      padding: 6px 16px;
      &__fullWidth {
        padding: 0;
      }
    }
    .formDisabledField {
      p {
        color: var(--mui-palette-black-black087) !important;
      }
    }
    .formToggleField {
      padding: 6px 0;
      margin: 8px 16px;
      border-bottom: 1px solid var(--mui-palette-customGrey-ceramic);
    }
    &.previewActions {
      padding: 0;
    }
  }
  &Button {
    width: auto !important;
    min-width: auto !important;
    &:disabled {
      color: var(--mui-palette-black-black026);
    }
  }
}

button.previewButton {
  padding: 16px;
  background-color: var(--mui-palette-white-opacity100);
  img {
    margin-right: 6px;
  }
}

.swiperContainer {
  flex: none;
  div:global(.swiper) {
    width: 100%;
    height: auto;
  }

  div:global(.swiper-slide) {
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    .swiperItem {
      width: 100%;
      aspect-ratio: 150 / 250;
      margin: 16px 0;
      border-radius: 10px;
      overflow: hidden;
      transition: all 200ms linear;
      transform: scale((116/162));
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:global(.swiper-slide-active) {
      .swiperItem {
        box-shadow: 0px 4px 10px var(--mui-palette-black-opacity20);
        transform: scale(1);
      }
    }
  }

  div:global(.swiper-pagination-fraction) {
    position: static;
    padding-bottom: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: var(--mui-palette-black-black058);
    :global(.swiper-pagination-current) {
      font-weight: 600;
      color: var(--mui-palette-black-black087);
    }
  }
}

.noteBar {
  button {
    color: inherit;
  }
}

.emailContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 0;
  > button {
    all: unset;
    margin-right: 8px;
    border-radius: 27px;
    width: 50px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: var(--mui-palette-green-starbucks);
    flex: 0 0 auto;
  }
  .emailTag {
    background-color: var(--mui-palette-green-light);
    margin: 0 2px 5px 0px;
    img {
      width: 16px;
      height: 16px;
    }
    span {
      color: var(--mui-palette-green-starbucks);
    }
  }
}

.emailList {
  width: 100%;
  min-height: 30px;
  border-radius: 27px;
  &.error {
    border: 1px solid var(--mui-palette-error-main);
  }
}
