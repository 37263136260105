.textInput {
  border: none;
  color: var(--mui-palette-green-starbucks);
  outline: none;
  background-color: transparent;
  font-size: 16px;
}
.itemWrapper {
  border: 1px solid #edebe9;
  border-radius: 5px;
  padding: 20px 16px;
  & > .textInput {
    margin-bottom: 16px;
    width: 100%;
    &::placeholder {
      color: var(--mui-palette-black-black026);
    }
  }
}
.dragDropItem {
  background: var(--mui-palette-customGrey-natural);
  border-radius: 5px;
  padding: 8px;
  width: 100%;
  margin-bottom: 10px;
  user-select: none;
  display: flex;
  align-items: center;
  position: relative;
  &:global(.addButton) {
    font-size: 16px;
    color: var(--mui-palette-black-black038);
    outline: none;
    border: none;
  }
  & img {
    margin-right: 4px;
    user-select: none;
    pointer-events: none;
  }
  & :global(.closeBtn) {
    all: unset;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0;
    & img {
      margin: 0;
    }
  }
}

.dragDropContainer {
  display: block;
  & .dragDropItem {
    &:global(.dragging) {
      opacity: 0.6;
    }
    & .textInput {
      width: 70%;
      &::placeholder {
        color: var(--mui-palette-black-black038);
      }
    }
  }
}

.contentRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
  width: 100%;
  & label {
    font-size: 14px;
    line-height: 17px;
    color: var(--mui-palette-black-black087);
  }
  & button {
    all: unset;
  }
  & :global(.pollingDetail) {
    color: var(--mui-palette-black-black038);
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    & img {
      margin-left: 4px;
    }
  }
}

.popupHeader {
  & :global(.desc) {
    font-size: 12px;
    line-height: 14px;
    color: var(--mui-palette-black-black038);
    font-weight: 400;
  }
}

.formList {
  & :global(.formListItem) {
    & label {
      outline: none;
      width: 100%;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding: 16px;
      font-size: 14px;
      line-height: 17px;
      color: var(--mui-palette-black-black087);
      cursor: pointer;
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        border-radius: 50%;
      }
      &::after {
        right: 16px;
        width: 20px;
        height: 20px;
        border: 1px solid var(--mui-palette-black-black026);
      }
    }
    & input[type="radio"] {
      display: none;
      &:checked + label {
        &::before {
          right: 20px;
          width: 14px;
          height: 14px;
          background-color: var(--mui-palette-green-starbucks);
        }
        &::after {
          border: 1px solid var(--mui-palette-green-starbucks);
        }
      }
    }
  }
}

.toggleButtonWrapper {
  width: 100%;
  & p {
    font-size: 14px;
    line-height: 17px;
    color: var(--mui-palette-black-black087);
  }
}
.timePickerContainer {
  padding: 16px;
  gap: 24px;
  p {
    color: var(--mui-palette-black-black087);
  }
}

.timePickerWrapper {
  position: relative;
  :global(.picker-container) {
    :global(.picker-inner) {
      padding: 0 20px;
      -webkit-mask-box-image: none;
    }
    :global(.picker-column) {
      max-width: 80px;
      :global(.picker-item) {
        color: var(--mui-palette-black-black026);
        font-size: 14px;
        &:global(.picker-item-selected) {
          color: var(--mui-palette-primary-main);
          font-size: 16px;
        }
      }
    }
    :global(.picker-highlight) {
      &:after,
      &:before {
        background-color: var(--mui-palette-black-black026);
      }
    }
  }
}
