.popupHeader {
  gap: 10px;
  .button {
    padding: 0;
  }
  .resetFilterButton {
    display: block;
    text-decoration: none;
    color: var(--mui-palette-primary-main);
  }
}

.inboxMsgItem {
  border-bottom: 1px solid var(--mui-palette-customGrey-seashell);
  .inboxMsgButton {
    padding: 16px;
  }
  .inboxMsgNotification {
    width: 8px;
    span {
      display: block;
      width: 100%;
      aspect-ratio: 1/1;
      border-radius: 100%;
      background-color: var(--mui-palette-green-apron);
    }
  }
  .inboxMsgAvatar {
    width: 50px;
    height: 50px;
    position: relative;
    &.multiAvatar {
      .avatar {
        &:last-child {
          position: absolute;
          right: 0;
          bottom: 0;
          border: 2px solid var(--mui-palette-white-opacity100);
          z-index: 2;
        }
      }
    }
    &:not(.multiAvatar) {
      .avatar {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.filterContainer {
  .filterField {
    padding: 4px 0;
  }
  .filterTitle {
    line-height: normal;
    padding-top: 8px;
    p {
      color: var(--mui-palette-black-black038);
    }
  }
  .filterItem {
    padding-top: 16px;
    padding-bottom: 16px;
    gap: 24px;
    color: var(--mui-palette-text-primary);
    &.active,
    &.active:hover {
      background-color: transparent;
    }
    p {
      line-height: 24px;
    }
    img {
      display: block;
      width: 24px;
      height: 24px;
    }
  }
}
