.ticketsContainer {
   background-color: var(--mui-palette-background-default);
   padding: 40px 16px;
}

.ticket {
   width: 100%;
   position: relative;
   border-radius: 10px;
   filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.2));
   margin: 0 auto;
   overflow: hidden;
   &Header {
      padding: 12px 16px;
      background-color: var(--mui-palette-primary-main);
      color: var(--mui-palette-background-default);
   }
   &TopLine, &BottomLine {
      width: 100%;
      height: 6px;
      padding-left: 6px;
      padding-right: 6px;
      position: relative;
      background-clip: content-box;
      overflow: hidden;
      &:before, &:after {
         content: '';
         position: absolute;
         display: block;
         width: 12px;
         height: 12px;
         border-radius: 100%;
         background-color: transparent;
      }
      &:before {
         left: -12px;
      }
      &:after {
         right: -12px;
      }
   }
   &TopLine {
      background-color: var(--mui-palette-background-default);
      &:before, &:after {
         top: -6px;
         border: 6px solid var(--mui-palette-white-opacity100);
      }
   }
   &BottomLine {
      background-color: var(--mui-palette-customGrey-natural);
      &:before, &:after {
         bottom: -6px;
         border: 6px solid var(--mui-palette-customGrey-natural);
      }
   }
   &Content {
      position: relative;
      background-color: var(--mui-palette-background-default);
      padding-top: 4px;
      padding-bottom: 28px;
      p {
         padding: 24px 16px;
      }
   }
   &QrCode {
      position: relative;
      &Container {
         padding: 16px;   
      }
      svg {
         max-width: 100%;
      }
   }
   &CheckedIn {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: var(--mui-palette-white-opacity90);
      &Label {
         padding: 4px 10px;
         background-color: var(--mui-palette-green-light);
         color: var(--mui-palette-primary-main);
      }
   }
   &Info {
      min-height: 164px;
      padding: 0 16px 8px;
      background-color: var(--mui-palette-customGrey-natural);
   }
   &Item {
      align-items: flex-start;
      img {
         display: block;
         margin-right: 8px;
      }
      p:not(:first-child) {
         color: var(--mui-palette-background-default)
      }
   }
}
