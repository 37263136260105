.cardWrapper {
  box-shadow: none !important;
  &.cardSize {
    &--small {
      padding: 10px;
      .cardContent {
        padding: 0px;
      }
      .profileIcon {
        width: 30px;
        height: 30px;
      }
      .cardHeader {
        margin-bottom: 4px;

        .subInfo {
          margin-top: 2px;
        }
      }
      .fullwidthPhoto {
        margin-top: 4px;
      }
      .caption {
        margin-bottom: 4px;
      }
    }
    &--large {
      padding: 0px;
      &.admin,
      &.adminRead,
      &.scheduled {
        .cardContent {
          padding-bottom: 16px;
        }
      }
      .cardContent {
        padding: 16px 0px 0px 16px;
        // padding: 16px;
      }
      .profileIcon {
        width: 40px;
        height: 40px;
      }
      .cardHeader {
        margin-bottom: 10px;
        .subInfo {
          margin-top: 4px;
        }
        .pin {
          width: 20px;
          margin: 2px;
        }
      }
      .fullwidthPhoto {
        margin-top: 16px;
        margin-left: -16px;
        margin-bottom: -16px;
        border-radius: 0;
        // padding-top: calc(100% / 286 * 161);
        width: calc(100% + 32px);
      }
      .mediaContainer {
        margin: 10px 16px 16px 16px;
      }
      .caption {
        padding-right: 16px;
      }
    }
  }
  .cardContent {
    padding: 0px;
    .caption {
      color: var(--mui-palette-black-opacity87);
      overflow-wrap: break-word;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      white-space: pre-line;

      .link {
        color: var(--mui-palette-green-accent);
        text-decoration: underline;
      }
    }
    .title {
      font-weight: 600;
    }
  }

  .mediaContainer {
    .keyMedia {
      margin-bottom: 8px;
      // padding-top: calc(100% / 286 * 161);
      border-radius: 4px;
    }
    .fileContainer {
      height: 192px;
      background-color: var(--mui-palette-customGrey-natural);
      .fileIcon {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
    .subMediaContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 8px;
      position: relative;
      margin-top: 8px;
      .fileContainer {
        position: absolute;
        width: 100%;
        max-height: 100%;
        top: 0;
        img {
          position: absolute;
        }
      }
      .subMediaWrapper {
        position: relative;
        padding-top: calc(100% / 286 * 161);
      }
      .photoContainer {
        // height: calc(100% / 286 * 161);
        position: absolute;
        max-height: 100%;
        border-radius: 4px;
        top: 0;
      }
      .moreMedia {
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
        box-sizing: border-box;
        background: var(--mui-palette-black-opacity100);
        opacity: 60%;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          color: var(--mui-palette-white-opacity100);
          font-weight: 600;
        }
      }
    }
    .videoThumbnail {
      width: 100%;
      height: 100%;
      position: absolute;
      right: 0;
      bottom: 0;
      box-sizing: border-box;
      background: rgba($color: #000000, $alpha: 0.3);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .pollContainer {
    margin: 0px 16px 16px 0px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    .pollTypeContainer {
      display: flex;
      column-gap: 4px;
      p {
        color: var(--mui-palette-green-darkApron);
      }
    }
    .pollDetailsContainer {
      display: flex;
      column-gap: 4px;
      align-items: center;
      justify-content: space-between;
      color: var(--mui-palette-black-opacity38);
      button {
        text-transform: none;
        width: auto;
      }
    }
  }

  .postActionInfoContainer {
    display: flex;
    padding: 8px 16px;
    column-gap: 24px;
    color: var(--mui-palette-black-opacity58);
    .infoItem {
      display: flex;
      align-items: center;
      column-gap: 4px;
    }
    img {
      width: 20px;
    }
  }
  .postActionBtnContainer {
    display: flex;
    padding: 10px 0px;
    border-top: 1px solid var(--mui-palette-black-opacity06);
    border-bottom: 1px solid var(--mui-palette-black-opacity06);
    & :global(.Mui-disabled) {
      opacity: 30%;
    }
    .infoItem {
      display: flex;
      align-items: center;
      column-gap: 4px;
      padding: 0px;
      text-transform: none;
      color: var(--mui-palette-black-opacity58);
    }
    img {
      width: 24px;
    }
    div,
    button {
      flex: 1 1 0;
    }
  }
  & :global(.MuiCardMedia-root) {
    box-shadow: none;
  }
  .adminBtnContainer {
    padding: 16px;
    display: flex;
    column-gap: 10px;
    border-top: 1px solid var(--mui-palette-black-opacity06);
    &.fixedBottom {
      position: fixed;
      bottom: 0;
      width: 100%;
      background: var(--mui-palette-white-opacity100);
      box-shadow: 0px -2px 15px 0px #0000000d;
      z-index: 1000;
    }
  }
  .postAdminInfoContainer {
    padding: 16px 16px 0px;
    // margin-bottom: -16px;
    .postAdminInfoRow {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      column-gap: 8px;
      .label {
        min-width: max-content;
      }
      .reportedBy {
        display: flex;
        align-items: center;
        text-align: right;
        img {
          padding: 5px;
          margin-left: 4px;
        }
      }
    }
  }
  .noticeBox {
  }
}
.cardHeader {
  display: flex;
  align-items: center;
  column-gap: 8px;

  .profileIcon {
    border-radius: 50%;
    border: 1px solid var(--mui-palette-white-opacity100);
    background-size: cover;
    background-position: center;
    position: relative;

    .starIcon {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .userName {
    font-weight: 600;
    color: var(--mui-palette-black-opacity87);
  }
  .subInfo {
    display: flex;
    align-items: center;
  }

  .adminTag {
    padding: 2px 4px;
    margin-right: 8px;
    background-color: var(--mui-palette-green-light);
    color: var(--mui-palette-green-accent);
    border-radius: 4px;
    font-size: 10px;
    line-height: 12px;
  }
  .timeStamp {
    color: var(--mui-palette-black-opacity38);
  }

  .endIconContainer {
    margin-left: auto;
    margin-right: 0;
    display: flex;
    column-gap: 10px;
  }
}
.popupTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &Block {
    display: block;
    & span {
      line-height: 23px;
    }
    & :global(.desc) {
      margin-top: 4px;
      font-size: 12px;
      line-height: 16px;
      color: var(--mui-palette-black-black038);
    }
  }
}
.popupContent {
  display: block;
  padding: 16px;
  margin-bottom: 24px;
  width: 100%;
  & label {
    color: var(--mui-palette-black-black058);
    font-weight: 600;
    font-size: 14px;
  }
  & :global(.formControl) {
    margin-top: 20px;
    & textarea {
      width: 100%;
      padding: 15px 0px;
      resize: none;
      border: none;
      outline: none;
      border-bottom: 1px solid #edebe9;
      font-size: 14px;
      color: var(--mui-palette-text-primary);
      &::placeholder {
        color: var(--mui-palette-black-black026);
      }
    }
    & :global(.textCount) {
      font-size: 12px;
      line-height: 16px;
      color: var(--mui-palette-black-black026);
      float: right;
      margin-right: 16px;
      margin-top: 4px;
      &:global(.textOver) {
        color: var(--mui-palette-error-main);
      }
    }
  }
}
.mediaPopup {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  background: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 20%,
    rgba(0, 0, 0, 1) 100%
  );
  &.showText {
    z-index: 1000;
    .popupContentWrapper {
      .caption {
        -webkit-line-clamp: inherit;
      }
    }
  }
  .popupContentWrapper {
    padding: 12px 16px;
    .cardHeader {
      padding: 10px 0px;
      .profileIcon {
        border: none;
        width: 40px;
        height: 40px;
      }
      .userName {
        color: var(--mui-palette-white-opacity100);
      }
      .timeStamp {
        color: #9e9e9e;
      }
    }

    .caption {
      color: var(--mui-palette-white-opacity100);
      overflow-wrap: break-word;
      margin: 4px 0px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      transition: all 0.5s ease;
    }
  }
  p {
    color: var(--mui-palette-white-opacity100);
  }
}

.listWrapper {
  & :global(.MuiStack-root) {
    width: 100% !important;
    padding: 16px;
  }
  & :global(.inputWrapper) {
    padding: 0px !important;
    column-gap: 12px !important;
    width: 100% !important;
    &:before {
      border: none !important;
    }
    & p {
      color: #212121 !important;
    }
  }
}

.reportDetailPopup {
  overflow: hidden;
  position: relative;
  & :global(.reportDetailContainer) {
    height: 100%;
  }
  & :global(.reportList) {
    background-color: var(--mui-palette-customGrey-natural);
    padding-top: 1px;
  }
  & :global(.MuiListItem-root) {
    margin-bottom: 8px;
    &:last-child {
      margin: 0;
    }
  }
  & :global(.reportDetailCard) {
    background-color: white;
    padding: 16px;
    width: 100%;
    & :global(.cardTop) {
      margin-bottom: 6px;
      & span {
        font-size: 16px;
        color: var(--mui-palette-black-black087);
      }
    }
    & :global(.cardDetails) {
      font-size: 14px;
      line-height: 18px;
      align-items: center;
      & :nth-child(3) {
        min-width: 50%;
      }
      & :nth-child(4) {
        max-width: 50%;
        text-align: left;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    & :global(.cardTitle) {
      color: var(--mui-palette-black-black038);
      margin-bottom: 6px;
    }
    & :global(.cardContent) {
      color: var(--mui-palette-black-black087);
      text-align: right;
      max-width: 200px;
      margin-bottom: 6px;
      &:global(.lineClamp) {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}

.hashtag {
  color: var(--mui-palette-green-accent);
}

.groupName {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  font-weight: 400;
  color: var(--mui-palette-black-black058) !important;
  text-decoration: none !important;
  img {
    width: 12px;
    height: 12px;
  }
}
