.radioBtnContainter {
  row-gap: 16px;
  & :global(.MuiRadio-root) {
    display: none;
  }
  .radioBtnWrapper {
    position: relative;
    width: 100%;
    & :global(.MuiFormControlLabel-root) {
      width: 100%;
    }
    & :global(.MuiFormControlLabel-label) {
      padding: 16px 12px;
      width: 100%;
      border-radius: 5px;
      border: 1px solid var(--mui-palette-black-opacity12);
      color: var(--mui-palette-black-opacity87);
      &:global(.Mui-disabled) {
        color: var(--mui-palette-black-opacity87);
      }
    }
    .votes {
      position: absolute;
      right: 12px;
      top: calc(50% - (15.54px / 2));
      color: var(--mui-palette-black-opacity38);
      span {
        font-weight: 700;
        margin-left: 6px;
      }
    }
    &.active {
      & :global(.MuiFormControlLabel-root) {
        & :global(.MuiFormControlLabel-label) {
          background-color: var(--mui-palette-green-apron);
          color: var(--mui-palette-white-opacity100);
          border: none;
        }
      }
      .votes {
        color: var(--mui-palette-white-opacity100);
      }
    }
  }
}
.voteLabel {
  display: flex;
  align-items: center;
  gap: 8px;
  .imageContainer {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: #f0f0f0;
  }
  img {
    width: 24px;
    height: 24px;
    &.voteImage {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
