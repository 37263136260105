.textInput {
  border: none;
  color: var(--mui-palette-green-starbucks);
  outline: none;
  background-color: transparent;
  font-size: 16px;
}
.itemWrapper {
  & > .textInput {
    margin-bottom: 16px;
    width: 100%;
    &::placeholder {
      color: var(--mui-palette-black-black058);
    }
  }
}
.dragDropItem {
  padding: 8px;
  width: 100%;
  margin-bottom: 10px;
  user-select: none;
  display: flex;
  align-items: center;
  position: relative;
  .questionWrapper {
    padding-right: 40px;
  }
  &:global(.addButton) {
    font-size: 16px;
    color: var(--mui-palette-black-black038);
    outline: none;
    border: none;
  }
  & img {
    margin-right: 4px;
    user-select: none;
    pointer-events: none;
  }
  & :global(.closeBtn) {
    all: unset;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0;
    & img {
      margin: 0;
    }
  }
  .questionsType {
    color: var(--mui-palette-black-black038);
    margin-bottom: 4px;
  }
  .questionsContent {
    color: var(--mui-palette-black-black087);
    &.disableText {
      color: var(--mui-palette-black-black058);
    }
  }
}

.dragDropContainer {
  display: block;
  & .dragDropItem {
    &:global(.dragging) {
      opacity: 0.6;
    }
    & .textInput {
      width: 70%;
      &::placeholder {
        color: var(--mui-palette-black-black038);
      }
    }
  }
}
.questionTypeSelectField {
  justify-content: space-between;
  padding: 16px 0px;
  :first-child {
    color: var(--mui-palette-black-black087);
    opacity: 0.4;
  }
  :last-child {
    font-weight: 600;
    color: var(--mui-palette-green-accent);
  }
  .disabledText {
    color: var(--mui-palette-black-black058);
  }
}
.questionTypePopupHeader {
  img {
    margin-left: auto;
    margin-right: 0;
  }
}

.formList {
  & :global(.formListItem) {
    & label {
      outline: none;
      width: 100%;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding: 16px;
      font-size: 14px;
      line-height: 17px;
      color: var(--mui-palette-black-black087);
      cursor: pointer;
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        border-radius: 50%;
      }
      &::after {
        right: 16px;
        width: 20px;
        height: 20px;
        border: 1px solid var(--mui-palette-black-black058);
      }
    }
    & input[type="radio"] {
      display: none;
      &:checked + label {
        &::before {
          right: 20px;
          width: 14px;
          height: 14px;
          background-color: var(--mui-palette-green-starbucks);
        }
        &::after {
          border: 1px solid var(--mui-palette-green-starbucks);
        }
      }
    }
  }
}

.toggleButtonWrapper {
  width: 100%;
  & p {
    font-size: 14px;
    line-height: 17px;
    color: var(--mui-palette-black-black087);
  }
}
.selectPopupHeader {
  padding: 12px 16px;
  button {
    padding: 0;
    margin-right: 10px;
  }
  h3 {
    text-transform: capitalize;
  }
  .addButton {
    color: var(--mui-palette-green-accent);
    font-weight: 600;
    text-align: right;
  }
}

.questionFormWrapper {
  background-color: var(--mui-palette-customGrey-natural);

  .questionWrapper {
    background-color: var(--mui-palette-white-opacity100);
    margin-top: 8px;
    padding: 16px;

    .questionTypeDropdownField {
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      max-width: 100%;
      padding: 8px;
      background: #f9f9f9;
      border-radius: 5px;
      gap: 8px;
      img {
        display: block;
        width: 20px;
      }
      &.disabled {
        p {
          color: #bdbdbd;
        }
      }
    }
    .formField {
      margin-top: 12px;
    }
    .dragAndDropWrapper {
      margin-top: 16px;
    }
  }

  .commonFieldWrapper {
    background-color: var(--mui-palette-white-opacity100);
    margin-top: 8px;
    .formToggleField {
      padding: 12px 16px;
    }
    .formField {
      padding: 16px;
    }
  }

  .formField {
    display: flex;
    align-items: center;
    & label {
      font-size: 14px;
      line-height: 17px;
      color: var(--mui-palette-black-black038);
    }
    & button {
      all: unset;
      margin-left: auto;
      margin-right: 0;
    }
    & :global(.selectorField) {
      color: var(--mui-palette-black-black038);
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      & img {
        margin-left: 4px;
      }
      &:global(.error) {
        color: var(--mui-palette-error-main);
      }
    }
    .fieldWrapper {
      width: 100%;
      display: flex;
      padding: 15.5px 0px;
      border-bottom: 1px solid #edebe9;
      &:global(.error) {
        border-bottom: 1px solid var(--mui-palette-error-main);
      }
    }
  }

  .questionFormFooter {
    background-color: var(--mui-palette-white-opacity100);
    box-shadow: 0px -4px 1px 0px #0000000a;
    position: sticky;
    width: 100%;
    bottom: 0px;
    overflow: hidden;
    .footerItemContainer {
      padding: 8px 16px;
      overflow-x: scroll;
      display: flex;
      column-gap: 12px;
      &::-webkit-scrollbar {
        display: none;
      }
      .footerItem {
        min-width: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        background-color: var(--mui-palette-customGrey-seashell);
        &:global(.active) {
          background-color: var(--mui-palette-green-light);
        }
        &.addQuestionBtn {
          background-color: var(--mui-palette-white-opacity100);
          border: 1px solid #e0e0e0;
        }
      }
      // &:last-child {
      //   background-color: var(--mui-palette-white-opacity100);
      //   border: 1px solid #e0e0e0;
      // }
    }
    & .footerDroppableContainer {
      display: flex;
      gap: 10px;
    }
  }
}

.errorMsg {
  color: var(--mui-palette-error-main);
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px;
  margin-left: 16px;
}
