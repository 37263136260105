.contentContainer {
   background-color: var(--mui-palette-white-opacity100);
   width: 100%;
   flex: 1;
   overflow-y: auto;
   overflow-x: hidden;
}
.templateContainer {
   padding: 16px;
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-column-gap: 16px;
   grid-row-gap: 32px;
   .templateWrapper {
      img {
         border-radius: 5px;
         max-width: 100%;
      }
   }
   .templateTitle {
      margin-top: 8px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;  
      overflow: hidden;
   }
}
