.introductionWrapper {
    width: 100%;
    // height: 100%;
    // position: absolute;
    // z-index: 1000;
    background: var(--mui-palette-white-opacity100);
    // box-sizing: border-box;
    // flex-direction: row!important;
    .introductionItem {
        margin: auto;
    }
    .introductionImage {
        display: block;
        margin: 0 auto;
        max-width: 100%;
    }
    .introductionPage {
        // display: flex;
        // height: calc(100% - 98px);
        padding: 27.5px;
        // flex-direction: column;
        // text-align: center;
        // justify-content: center;
        // box-sizing: border-box;
        overflow: auto;
        .pagination {
            display: flex;
            justify-content: center;
            margin-top: 36px;
            margin-bottom: 56px;
            column-gap: 8px;
            .dot {
                height: 5px;
                width: 5px;
                opacity: 30%;
                border-radius: 50%;
                background-color: var(--mui-palette-green-starbucks);
                transition: all 0.5s ease;
                &.active {
                    width: 15px;
                    opacity: 100%;
                    border-radius: 100px;
                }
            }
        }
        .title {
            color: var(--mui-palette-black-opacity87);
        }
        .description {
            padding-top: 8px;
            color: var(--mui-palette-black-opacity38);
        }
    }
    .nextBtnContainer {
        // position:fixed;
        width: 100%;
        // box-sizing: border-box;
        // bottom: 0;
        padding: 24px 16px;
        box-shadow: 0px -2px 15px 0px #0000000D;
    }

}

.popupHeader {
    padding: 12px 16px;
    .button {
       padding: 0;
    }
}

.popupContainer {
    height: 100%;
    overflow: hidden;
}

.popupContent {
    height: 100%;
    overflow: hidden;
    padding: 0 16px;
}

.popupDescription {
    height: 100%;
    padding-bottom: 12px;
    overflow-x: hidden;
    overflow-y: auto;
    .title {
        padding: 16px 0;
        color: var(--mui-palette-black-opacity87);
    }
    .description {
        padding-right: 12px;
        color: var(--mui-palette-black-black058);
    }
}

.popupButton {
    position: relative;
    z-index: 1;
    padding: 24px 16px;
    box-shadow: 0px -2px 15px 0px var(--mui-palette-black-opacity05);
}