.eventContainer {
   display: flex;
   flex-direction: column !important;
   overflow: auto;   
}

.formContainer {
   .formWrapper {
      padding: 10px 0;
      background-color: #ffffff;
   }
   .formField {
      padding: 6px 16px;
      &__fullWidth {
         padding: 0;
      }
   }
   .formToggleField {
      padding: 14px 16px;
   }
}

.languageSelect {
   &Header {
      color: #212121;
   }
   &Description {
      padding-top: 4px;
      color: #9E9E9E;
   }
   &List {

   }
   &Option {
      padding: 18px;
      gap: 24px
   }
}


.deleteBtn{
   align-items: center;
   width: fit-content;
   margin: auto;
   p {
      color: var(--mui-palette-error-main);
   }
   img {
      width: 20px;
   }
}

.previewBtn {
   align-items: center;
   width: fit-content;
   margin: auto;
   p {
      color: var(--mui-palette-green-accent);
   }
   img {
      width: 20px;
   }
}