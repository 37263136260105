.searchResult {
   display: flex;
   flex-direction: column !important;
   overflow: auto;
   background-color: var(--mui-palette-background-default); 
   height: 100%;
   .postList {
      width: 100%;
      background-color: var(--mui-palette-customGrey-natural); 
   }
}

.emptyResultContainer{
   padding: 0px 32px 100px;
   margin: auto;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   text-align: center;
   row-gap: 16px;
}

.listWrapper{
   padding: 0;
   .filterOption{
      padding: 16px;
   }
}
.filterTagContainer{
   width: 100%;
   padding: 24px 16px;
   flex-flow: wrap;
   gap: 8px;
   & :global(.MuiChip-root){
      background-color: var(--mui-palette-green-light);
      color: var(--mui-palette-green-starbucks);
   }
}
