#mediaPopup {
  :global(.MuiPaper-root) {
    background-color: #000;
    :global(.MuiDialogTitle-root) {
      border: none;
    }
  }
  .mediaHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    * {
      all: unset;
    }
    button {
      line-height: 0;
    }
    .mediaTitle {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      color: #fff;
      button {
        margin-right: 10px;
      }
    }
  }
  .mediaContent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 60px);
    img,
    video {
      width: fit-content;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
