.toolbar {
   gap: 16px;
   button {
      padding: 0;
   }
}

.eventContainer {
   // display: flex;
   // flex-direction: column !important;
   overflow: auto;
   align-content: flex-start;
   .eventMedia {
      width: 100%;
      position: relative;
      img {
         display: block;
         width: 100%;
      }
      &Skeleton {
         padding-top: calc(100% / 375 * 210);
      }
   }
   .eventCategory {
      position: absolute;
      left: 0;
      bottom: 0;
   }
   .eventInformationCard {
      width: 100%;
      padding: 16px;
      margin-bottom: 8px;
      background-color: var(--mui-palette-background-default);
      h5 {
         color: var(--mui-palette-black-black038);
      }
      a {
         word-break: break-all;
      }
      .viewAllAttachmentBtn {
         text-decoration: none;
         color: var(--mui-palette-primary-main);
      }
   }
   .registerMsgBar {
      // bottom: 
      .registerMsg {
         width: 100%;
         // padding: 0 10px;
      }
   }
}

.eventInformationItem {
   .itemIcon {
      width: 20px;
      img {
         display: block;
         width: 100%;
      }  
   }
   .itemContent {
      p {
         padding: 2px 0;
         color: var(--mui-palette-black-black038);
         &:first-child {
            color: var(--mui-palette-black-black087);
         }
      }
   }
}

.eventInformationAbout {
   &Description, 
   p {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box !important;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      white-space: normal;
      color: var(--mui-palette-black-black087);
      &.more {
         -webkit-line-clamp: unset;
      }
   }
   table {
      width: 100% !important;
   }
   button {
      display: block;
      width: fit-content;
      margin-top: 4px;
      text-decoration: none;
      color: var(--mui-palette-primary-main);
      &[disabled] {
         display: none;
      }
   }
}

.eventAttendeesContainer {
   .list {
      margin-top: 12px;
   }
   .count {
      margin-top: 12px;
      text-align: right;
      span {
         color: var(--mui-palette-black-black038);
         &:first-child {
            color: var(--mui-palette-primary-main);
         }
      }
   }
}

.attachmentsContainer {
   overflow: auto;
   gap: 12px;
   .attachmentsItem {
      display: flex;
      height: 110px;
      border-radius: 4px;
      background-color: var(--mui-palette-background-default);
      overflow: hidden;
      &[data-attachment-type=file] {
         width: 110px;
      }
   }
   img {
      display: block;
      max-height: 100%;
   }
}

.registerActionsContainer {
   padding: 24px 16px;
   background-color: var(--mui-palette-background-default);
   box-shadow: 0px -2px 15px var(--mui-palette-black-opacity04);
   .unregisterBtn {
      margin-top: 8px;
      font-weight: 600;
      font-size: 15px;
      line-height: 19px;
      color: var(--mui-palette-green-darkApron);
      text-decoration: none;
   }
}

.manage {
   &List {

   }
   &CloseBtn {
      padding: 0;
   }
   li &Option {
      padding: 16px;
      gap: 12px;
   }
}
