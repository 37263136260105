.postContainer {
  background-color: var(--mui-palette-white-opacity100);
}
.noPostContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: var(--mui-palette-white-opacity100);

  img {
    margin-bottom: 16px;
  }
  h3 {
    margin-bottom: 8px;
  }
  p {
    color: var(--mui-palette-black-opacity38);
    height: 63px;
    margin-bottom: 50%;
  }
}
.itemContainer {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 16px;
  .itemHeader {
    display: flex;
    justify-content: space-between;
    .statusTag {
      border-radius: 10px;
      padding: 3px 6px;
      &:global(.keep) {
        color: var(--mui-palette-green-apron);
        border: 1px solid var(--mui-palette-green-accent);
      }
      &:global(.suspend) {
        color: var(--mui-palette-warning-main);
        border: 1px solid var(--mui-palette-warning-main);
      }
      &:global(.recover) {
        color: var(--mui-palette-green-apron);
        border: 1px solid var(--mui-palette-green-apron);
      }
      &:global(.delete) {
        color: var(--mui-palette-black-opacity58);
        border: 1px solid var(--mui-palette-black-opacity58);
      }
      &:global(.resolved),
      &:global(.resolve) {
        color: var(--mui-palette-black-opacity38);
        border: 1px solid var(--mui-palette-black-opacity38);
      }
    }
  }
  .itemContent {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 8px;
    .label {
      color: var(--mui-palette-black-opacity38);
    }
    .content {
      color: var(--mui-palette-black-opacity58);
    }
  }
}
