
.postActionInfoContainer {
    display: flex;
    padding: 8px 16px;
    column-gap: 24px;
    color: var(--mui-palette-black-opacity58); 
    .infoItem {
        display: flex;
        align-items: center;
        column-gap: 4px;
    }
    img {
        width: 20px;
    }
}
.postActionBtnContainer {
    display: flex;
    padding: 10px 0px;
    border-top: 1px solid var(--mui-palette-black-opacity06);
    border-bottom: 1px solid var(--mui-palette-black-opacity06);
    .infoItem {
        display: flex;
        align-items: center;
        column-gap: 4px;
        padding: 0px;
        text-transform: none;
        color: var(--mui-palette-black-opacity58); 
    }
    img {
        width: 24px;
    }
    div, button {
        flex: 1 1 0;
    }
}

.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
 