.shortcutsWrapper {
  width: 100%;
  overflow-x: hidden;
  padding: 8px 0px;
  .shortcutItemContainer {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    padding: 0px 8px;
    // gap:8px;
    justify-content: flex-start;
    &::-webkit-scrollbar {
      display: none;
    }

    .shortcutItem {
      flex: 1 1 0;
      padding: 8px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      min-width: 65.4px;
      &.active {
        padding-top: 9px;
        p {
          color: var(--mui-palette-green-starbucks);
        }
      }
      img {
        margin-bottom: 8px;
        max-width: 24px;
      }
      p {
        word-break: normal;
      }
    }
    &.hkStyle {
      row-gap: 8px;
      .shortcutItem {
        img {
          max-width: 26px;
          width: 100%;
        }
      }
    }
  }
}
