.files {
  background-color: #fff;
  padding-bottom: 140px;
  position: relative;
  .createButton {
    all: unset;
    position: fixed;
    bottom: 60px;
    right: 0;
    z-index: 10;
  }
}

.listView {
  all: unset;
  * {
    all: unset;
  }
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #f0f0f0;
    .content {
      display: flex;
      width: calc(100% - 30px);
      img {
        width: 50px;
        flex: 0 0 50px;
      }
    }
    .info {
      padding-left: 10px;
      overflow: hidden;
      h3 {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-bottom: 4px;
      }
    }
  }
}

.gridView {
  padding: 0 16px;
  * {
    all: unset;
  }
  .item {
    & > div {
      width: 100%;
    }
    .img {
      display: flex;
      align-items: center;
      justify-content: center;
      // height: 88px;
      position: relative;
      padding: 0 12px;
      box-sizing: border-box;
      overflow: hidden;
      img {
        width: 46%;
      }
      video {
        border: 1px solid #f0f0f0;
        border-radius: 6px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 140 / 88;
      }
      .playBtn {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 24px;
      }
      .thumbnail {
        border: 1px solid #f0f0f0;
        border-radius: 6px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        aspect-ratio: 140/88;
      }
      .playButton {
        width: 24px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .info {
      text-align: center;
      position: relative;
      padding: 15px 24px;
      display: block;
      box-sizing: border-box;
      h3 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      button {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.info {
  width: 100%;
  * {
    display: block;
  }
  h3 {
    font-weight: 400;
    font-size: 14px;
    color: var(--mui-palette-black-black087);
  }
  p {
    font-weight: 400;
    font-size: 10px;
    color: var(--mui-palette-black-black038);
  }
}

#mediaPopup {
  :global(.MuiPaper-root) {
    background-color: #000;
    :global(.MuiDialogTitle-root) {
      border: none;
    }
  }
  .mediaHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    * {
      all: unset;
    }
    button {
      line-height: 0;
    }
    .mediaTitle {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      color: #fff;
      button {
        margin-right: 10px;
      }
    }
  }
  .mediaContent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 60px);
    img,
    video {
      width: 100%;
    }
  }
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .noDataContainer {
    text-align: center;
    h3 {
      font-weight: 600;
      font-size: 20px;
      color: var(--mui-palette-black-black087);
      margin-top: 16px;
      margin-bottom: 8px;
    }
    span {
      font-weight: 400;
      font-size: 14px;
      color: var(--mui-palette-black-black038);
    }
  }
}

.snackbar {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  gap: 10px;
  span {
    font-size: 12px;
    font-weight: 400;
  }
  &:global(.success) {
    background-color: #d4e9e2;
    span {
      color: #006241;
    }
  }
  &:global(.error) {
    background-color: #ffe6e6;
    span {
      color: #c82014;
    }
  }
}

.phListIndex {
  height: 100%;
  position: relative;
  & :global(.MuiPaper-root) {
    padding: 0;
  }
  & :global(.MuiAccordionDetails-root) {
    padding: 8px 16px;
  }
  & :global(.Mui-expanded) {
    min-height: 48px !important;
    // padding: 0px;
  }
  &:global(.MuiAccordionSummary-root) {
    padding: 0;
  }
  & :global(.MuiAccordionSummary-content) {
    margin: 0;
    &:global(.Mui-expanded) {
      margin: 0;
    }
  }
  & :global(.MuiPaper-root) {
    margin: 0;
    &:global(.MuiAccordion-root) {
      box-shadow: none;
      &:global(.Mui-expanded) {
        margin: 0;
      }
    }
  }
  .listContainer {
    padding-bottom: 80px;
  }
}
