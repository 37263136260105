.pageContainer {
   background: linear-gradient(105.68deg, #2E6F54 1.95%, #3B6B5D 16.79%, #116249 34.09%, #1C5D47 49.28%, #0E584A 70.16%, #225047 91.04%);
   
   .tabContainer {
      width: 100%;
      background-color: var(--mui-palette-white-opacity12);
      border-radius: 100px;
      margin-bottom: 16px;
      button {
         padding: 6px;
         color: var(--mui-palette-white-opacity70);
         font-size: 14px;
         font-weight: 400;
         line-height: 18px;

         &:global(.Mui-selected) {
            color: var(--mui-palette-white-opacity100);
         }
      }
      & :global(.MuiTabs-indicator) {
         display: none;
      }
   }

   .contentContainer {
      align-items: center;
      padding: 16px 16px 44px 16px;

      .date {
         padding: 8px 16px;
         color: var(--mui-palette-white-opacity100);
         font-weight: 700;
      }
     
      .topThreeContainer {
         position: relative;
         width: calc(100% - 20px);
         height: 232px;
         .crown {
            position: absolute;
            left: calc( 50% - (34px / 2));
         }
         .star1_1 {
            position: absolute;
            top: 5px;
            left: calc( 100%* 2.5 / 8 + 2px);
         }
         .star1_2 {
            position: absolute;
            width: 11px;
            top: 41px;
            right: calc( 100%* 2.5 / 8 + 7px);
         }
         .star2 {
            position: absolute;
            width: 11px;
            top: 89px;
            left: 5px;
         }
         .star3 {
            position: absolute;
            width: 7.5px;
            top: 112px;
            right: 2px;
         }

         .rankItem {
            position: absolute;
            align-items: center;
            justify-content: center;
            bottom: 0;
            padding-top: 20px;
            p {
               color: var(--mui-palette-white-opacity100);
               text-align: center;
               width: 100%;
               overflow-wrap: break-word;
            }
            .points {
               font-size: 15px;
               font-weight: 700;
               line-height: 18px;
            }
          
            &:global(.top1) {
               background-color: #2C7A60;
               width: calc( 100% * 3 / 8);
               left: calc( 100% * 2.5 / 8 );
               height: 150px;
               border-top-right-radius: 10px;
               border-top-left-radius: 10px;
               .iconWrapper{
                  position: absolute;
                  top: -35%;
                  left: calc(50% - 38px);
                  .rank {
                     &::before {
                        margin-left: -6px;
                        margin-top: -2px;
                        background: #EDD15B;
                     }
                  }
               }
               & :global(.MuiAvatar-root) {
                  width: 76px;
                  height: 76px;
                  border: 3px solid #EDD15B ;
               }
            }
            &:global(.top2), &:global(.top3) {
               width: calc( (100% * 2.5 / 8 ));
               background-color: #4B9775;
               height: 104px;
               .iconWrapper{
                  position: absolute;
                  top: -40%;
                  left: calc(50% - 30px);
               }
               & :global(.MuiAvatar-root) {
                  width: 60px;
                  height: 60px;
               }
            }

            &:global(.top2) {
               left: 0;
               border-top-left-radius: 10px;
               & :global(.MuiAvatar-root) {
                  border: 3px solid #CBA258 ;
               }
               .rank {
                  &::before {
                     margin-left: -6px;
                     margin-top: -1.5px;
                     background: #CBA258;
                  }
               }
            }

            &:global(.top3) {
               right: 0;
               border-top-right-radius: 10px;
               & :global(.MuiAvatar-root) {
                  border: 3px solid #A88300 ;
               }
               .rank {
                  &::before {
                     margin-left: -6px;
                     margin-top: -1.5px;
                     background: #A88300;
                  }
               }
            }
            
            & :global(.MuiAvatar-root) {
               margin: auto;
            }

            .rank {
               position: absolute;
               width: 100%;
               bottom: -3px;
               text-align: center;
               z-index: 1;
               color: var(--mui-palette-white-opacity100);
               font-weight: 700;
               &::before {
                  content: "";
                  position: absolute;
                  z-index: -1;
                  width: 20px;
                  height: 20px;
                  border-radius: 5px;
                  transform: rotate(45deg);
               }
            }
      
         }
      }
      .rankingList {
         width: 100%;
         background-color: var(--mui-palette-white-opacity100);
         padding: 16px;
         border-radius: 10px;  
         &.greyBg{
            background-color: var(--mui-palette-black-opacity06);
            border-bottom-right-radius: 0px;  
            border-bottom-left-radius: 0px;  
            & :global(.MuiAvatar-root) {
               width: 50px;
               height: 50px;
            }
         }
         &.notRoundCorner{
            border-top-right-radius: 0px;  
            border-top-left-radius: 0px;  
         }
         .rankItem {
            align-items: center;
            .rank {
               min-width: 18px;
            }
         }
        
         .pointsContainer{
            margin-left: auto;
            margin-right: 0px;
            .note{
               color: var(--mui-palette-black-black058);
            }
         }
         .points {
            margin-left: auto;
            margin-right: 0px;
            span {
               font-size: 12px;
               line-height: 16px;
               color: var(--mui-palette-black-black038);
            }
         }
      }

      .AccordionContainer {
         width: 100%;
         & :global(.MuiAccordionDetails-root) {
            padding: 0px;
            .rankingList { 
               &.greyBg{
                  border-radius: 0px;  
               }
            }
         }
         & :global(.MuiAccordion-root) {
            &:first-of-type{
               border-top-left-radius: 10px;
               border-top-right-radius: 10px;
            }
            &:last-of-type{
               border-bottom-left-radius: 10px;
               border-bottom-right-radius: 10px;
            }
         }
         & :global(.Mui-expanded) {
            margin: 0px;
         }
         & :global(.MuiAccordionSummary-expandIconWrapper) {
            &:global(.Mui-expanded) {
               transform: rotate(90deg);
            }
         }

         .emptyList {
            padding: 16px;
         }
      }
   }
  
} 
.noResultsContainer {
   background-color: var(--mui-palette-white-opacity100);
   display: flex;
   flex-direction: column !important;
   overflow: auto;
   height: 100%;
   margin-top: -50px;
}