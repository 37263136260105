.groupIcon {
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 5px;
  background-color: #1E3932;
  img {
    display: block;
    width: 20px;
    height: 20px;
  }
  &[data-group=operation] {
    background-color: #1E3932;
  } 
  &[data-group=district] {
    background-color: #006241;
  } 
  &[data-group=store] {
    background-color: #00A862;
  } 
}