.container {
  background-color: #fff;
  padding-bottom: 16px;
}
.section {
  padding: 16px;
  + .section {
    border-top: 1px solid var(--mui-palette-customGrey-natural);
  }
}
.row {
  display: flex;
  flex-direction: column;
  gap: 8px;
  + .row {
    margin-top: 12px;
  }
  label {
    font-size: 14px;
    color: #9e9e9e;
  }
  .text {
    font-size: 14px;
    color: #212121;
  }
}
.copyLink {
  width: calc(100% - 32px) !important;
  height: 51px !important;
  margin: 0 auto !important;
  display: block !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.transferredCouponsAlert {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 16px 0;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 16.8px;
  color: #212121;
}
