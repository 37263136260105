.headerContainer {
   background: linear-gradient(105.68deg, #2E6F54 1.95%, #3B6B5D 16.79%, #116249 34.09%, #1C5D47 49.28%, #0E584A 70.16%, #225047 91.04%);
   .infoContainter{
      justify-content: center;
      padding: 16px;
      .infoData{
         align-items: flex-end;
      }
   }
   .infoWrapper {
      align-items: center;
      width: 50%;
      p, h2 {
         color: var(--mui-palette-white-opacity100);
      }
     
   }
   .seasonEndDate {
      width: 100%;
      text-align: center;
      color: var(--mui-palette-white-opacity100);
      padding: 8px 16px;
   }

   .menuBackground{
      padding: 0px 16px; 
      position: relative;
      &::before {
         content: "";
         position: absolute;
         background-color: var(--mui-palette-customGrey-natural);
         width: 100%;
         height: 50%;
         bottom: 0;
         left: 0;
         z-index: 0;
      }  
      .menuWrapper{
         position: relative;
         background-color: var(--mui-palette-white-opacity100);
         border-radius: 10px;
         z-index: 1;
      }
   }
}

.contentContainer {
   background-color: var(--mui-palette-customGrey-natural);
}

.missionCointainer{
   padding: 16px;
   overflow: hidden;
   .title {
      font-weight: 700;
      line-height: 17px;
      color: var(--mui-palette-green-house);
      padding-bottom: 16px;
      letter-spacing: 0.1em;
   }
   .missionCardCointainer{
      column-gap: 8px;
      // overflow-y: scroll;
      .missionCardWrapper{
         // min-width: 142px;
         // width: 100%;
         height: 120px;
         padding: 16px;
         border-radius: 10px;
         background-color: var(--mui-palette-white-opacity100);
         background-size: auto;
         background-repeat: no-repeat;
         background-position: 100% 0%;
         justify-content: space-between;

         .cardTitle {

         }
         .cardBottom{
            align-items: center;
            column-gap: 4px;
            .cardPoints {
               font-weight: 600;
               color: var(--mui-palette-black-opacity90);
            }
            .cardUnit {
               line-height: 14px;
               color: var(--mui-palette-black-black038);
            }
         }
      }
   }
}

.campaignCointainer {
   background-color: var(--mui-palette-white-opacity100);
   .campaignDescCointainer {
      padding: 16px; 
      white-space: pre-line;
      .title {
         color: var(--mui-palette-green-house);
         padding-bottom: 8px;
      }
      .caption{
         color: var(--mui-palette-black-black058);
      }
   }.campaignItemWrapper{
      justify-content: space-between;
   }
   .campaignItemCointainer {
      .campaignItemWrapper {
         img {
            max-width: 70px;
            border-radius: 5px;
         }
         padding: 16px;
         align-items: center;
      }
   }
}

.popupTitle {
   display: flex;
   align-items: center;
   justify-content: space-between;
}
.popupContent {
   padding: 16px 16px 24px 16px;
   color: var(--mui-palette-black-black087);
   font-size: 14px;
   font-weight: 400;
   p, h5 {
      line-height: 18.13px;
   }
}
