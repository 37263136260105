.selectPopupHeader {
  padding: 12px 16px;
  gap: 10px;
  color: var(--mui-palette-black-black087);
  border-bottom: 1px solid var(--mui-palette-divider);
  .closeSelectPopupButton {
    width: 28px;
    height: 28px;
    padding: 0;
  }
}

.selectPopupActions {
  position: relative;
  box-shadow: 0px -2px 15px var(--mui-palette-black-opacity05);;
  .confirmMultipleValue {
    padding: 4px 0;
    overflow: hidden;
    p {
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.selectPopupWrapper {
  overflow: hidden;
  position: relative;
  .selectOptionsContainer {
    height: 100%;
  }
  .selectedOptionsContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--mui-palette-background-default);
    display: flex;
    flex-direction: column;
  }
  &[data-open-selected=true] {
    .selectOptionsContainer {
      pointer-events: none;
    }
  }
}

.tagFieldActions {
  width: 100%;
  padding: 8px 0;
  gap: 8px;
}
