.tag {
    background-color: #C3B5AC;
    padding: 2px 8px;
    color: var(--mui-palette-customGrey-natural);
    border-radius:  0px 4px 0px 4px;
    &[data-category=SPORTS] {
        background-color: #C3B5AC;
    }
    &[data-category=CONCERTS] {
        background-color: #C3B5AC;
    }
    &[data-category=MEETINGS_AND_CONFERENCES] {
        background-color: #C3B5AC;
    }
    &[data-category=FESTIVALS] {
        background-color: #E18F7E;
    }
    &[data-category=WORKSHOP] {
        background-color: #8687F1;
    }
    &[data-category=INCENTIVES] {
        background-color: #C3B5AC;
    }
    &[data-category=HISTORICAL] {
        background-color: #C3B5AC;
    }
    &[data-category=CULTURAL_ARTS] {
        background-color: #C3B5AC;
    }
    &[data-category=SERVICE] {
        background-color: #D7B984;
    }
    &[data-category=OTHERS] {
        background-color: #C3B5AC;
    }
}