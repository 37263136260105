.searchResult {
   display: flex;
   flex-direction: column !important;
   overflow: auto;
   background-color: var(--mui-palette-background-default);
   height: 100%;
}

.eventResultCard {
   padding: 16px;
   gap: 16px;
   .eventResultImage {
      width: 122px;
      height: 72px;
      border-radius: 3px;
      overflow: hidden;
      img {
         display: block;
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }
   .eventResultContent {
      .eventResultCategory {
         width: fit-content;
      }
      .eventResultTitle {}
      .eventResultDate {
         color: var(--mui-palette-black-black058);
         li {
            font-size: 12px;
            line-height: 16px;
            &[aria-hidden=true] {
               margin: 0 4px;
            }
         }
      }
      .eventResultLocation {
         gap: 4px;
         color: var(--mui-palette-black-black058);
         img {
            width: 14px;
         }
      }
   }
}