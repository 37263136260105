.sectionContainer {
  width: 100%;

  .sectionWrapper {
    padding: 16px;
    .sectionHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      .sectionTitle {
        color: var(--mui-palette-green-house);
        font-weight: 700;
      }
    }
    &.hkStyle {
      background: #f9f9f9;
      .subtext {
        text-align: center;
        padding-top: 20px;
      }
      .value {
        font-size: 28px;
        line-height: 21px;
      }
      .title {
        line-height: 14.4px;
        width: 80%;
        text-align: center;
        margin: auto;
      }
    }
  }
  .subtext {
    font-size: 12px;
    font-weight: 400;
    color: var(--mui-palette-black-black038);
  }
}
.noticeContainer {
  max-width: 100px;
  margin: auto;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  row-gap: 16px;
}
.swiperItem {
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 150/90;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  height: 90px;
  label {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  }
  &.more {
    background-color: #1e3932;
    aspect-ratio: 80/90;
  }
  &.hkStyle {
    aspect-ratio: 300/150;
    min-height: 134px;
    height: auto;
  }
}

.storeAndFinancial {
  display: flex;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 32px;
  .item {
    padding: 8px;
    border-radius: 10px;
    background-color: #f9f9f9;
    text-align: center;
    width: 100%;
    img {
      width: 24px;
      height: 24px;
    }
    .value {
      font-size: 16px;
      color: #000;
      font-weight: 600;
      margin-bottom: 4px;
      line-height: normal;
    }
    .title {
      font-size: 12px;
      font-weight: 400;
      color: var(--mui-palette-black-black058);
      line-height: normal;
    }
  }
}
.partnerNetworkSwiper {
  :global(.groupImage) {
    aspect-ratio: 300 / 150 !important;
    background-size: cover !important;
  }
}
