.searchResult {
   display: flex;
   flex-direction: column !important;
   overflow: auto;
   background-color: #ffffff; 
   .postList {
      width: 100%;
      background-color: var(--mui-palette-customGrey-natural); 
      display: flex;
      flex-direction: column !important;
      overflow: auto;
      height: 100%;
   }
}

.SocialWallCard {
   margin-top: 8px;
   padding: 16px;
   background-color: #ffffff; 
}

.emptyResultContainer {
   padding: 0px 32px 100px;
   margin: auto;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   text-align: center;
   row-gap: 16px;
}

.recentSearchHeader {
   padding: 16px;
   justify-content: space-between;
   .clearBtn {
      color: var(--mui-palette-green-apron);
   }
}