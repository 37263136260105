.checkboxTreeList {
  ol {
    margin: 0;
    padding-left: 0;
    list-style-type: none;
  }
  button {
    color: inherit;
    border: 0;
    background: none;
    line-height: normal;
    font-size: 12px;
    padding: 0;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }
  label {
    margin-bottom: 0;
    cursor: pointer;
  }
  input {
    display: none;
  }
  img {
    display: block;
  }
  span[class=rct-title] {
    flex: 1;
    display: flex;
    align-items: center;
    min-height: 30px;
    box-sizing: content-box;
    padding-top: 13px;
    padding-bottom: 13px;
    border-bottom: 1px solid #F0F0F0;
  }
  span[class=rct-checkbox] {
    order: 9;
  }
}

.optionItem {
  span[class=rct-text] {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 16px;
    flex-direction: row-reverse;
    position: relative;
    & > button {
      position: absolute;
      & + label {
        span[class=rct-title] {
          flex: 1;
          padding-right: 32px;
        }
      }
    }
    label,
    & :global(.rct-bare-label) {
      display: flex;
      align-items: center;
      flex: 1;
      gap: 8px;
    }
  }
}

.optionSubItem {
  display: block;
}

.optionLastItem {
  span[class=rct-node-icon] {
    padding-left: 30px
  }
}

.checkboxIcon {
  width: 24px;
  height: 24px;
  span {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 1px solid #000;
    margin: 2px;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.expendArrow {
  width: 24px !important;
  height: 24px;
  padding: 2px;
  transform: rotate(90deg);
  transition: transform 200ms linear;
  transform-origin: center center;
  img {
    display: block;
    width: 20px !important;
    height: 20px;
  }
  &[data-open=true] {
    transform: rotate(-90deg);
  }
}
