.storeInfoContainer {
   padding: 16px;
   background-color: var(--mui-palette-white-opacity100);
   margin-bottom: 8px;
} 
.partnerInfoContainer {
   padding: 16px;
   background-color: var(--mui-palette-white-opacity100);
   height: 100%;
   h5 {
      margin-bottom: 12px;
   }
}

.storeNameWrapper{
   h3 {
      margin-bottom: 4px;
   }
}
.partnersContainer {
   cursor: pointer;
   img{  
      display: inline-block;
      margin-left: -8px;
      border: 1px solid var(--mui-palette-customGrey-natural);
      border-radius: 50%;
      width: 40px;
      height: 40px;  
      &:first-child {
         margin-left: 0px;
      }
   }
   .lastItem{
      position: relative;
      display: inline-block;
      margin-left: -8px;
      img {
         margin-left: 0px;
       
      }
      h5 {
         position: absolute;
         top: 0px;
         color: var(--mui-palette-customGrey-natural);
         width: 40px;
         height: 40px;
         display: flex;
         justify-content: center;
         align-items: center;
      }
      &::before {
         content: "";
         position: absolute;
         top: 0px;
         left: 0px;
         width: 40px;
         height: 40px;
         border-radius: 50%;
         background-color: var(--mui-palette-black-opacity100);
         opacity: 0.6;
      }
   }
}

.popup {
   &Header {
      gap: 10px;
      .backButton {
         padding: 0;
      }
   }
   &Container {
      height: 100%;
      overflow: hidden;
   }
   &Content {
      overflow-y: auto;
      overflow-x: hidden;
   }
}
