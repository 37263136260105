.eventContainer {
   display: flex;
   flex-direction: column !important;
   overflow: auto;   
}

.formContainer {
   .formWrapper {
      padding: 10px 0;
      background-color: var(--mui-palette-background-default);
   }
   .formField {
      padding: 6px 16px;
      &__fullWidth {
         padding: 0;
      }
   }
   .formToggleField {
      padding: 14px 16px;
   }
   &Loading {
      height: 100%;
   }
}

.languageSelectField {
   display: flex;
   align-items: center;
   justify-content: center;
   width: fit-content;
   max-width: 100%;
   padding: 8px;
   background: var(--mui-palette-customGrey-natural);
   border-radius: 5px;
   gap: 8px;
   img {
      display: block;
      width: 20px
   }
   &.disabled {
      p {
         color: var(--mui-palette-black-black026)
      }
   }
}

.languageSelect {
   &Header {
      color: var(--mui-palette-text-primary);
   }
   &Description {
      padding-top: 4px;
      color: var(--mui-palette-black-black038);
   }
   &List {

   }
   &Option {
      padding: 18px;
      gap: 24px
   }
}

button.saveButton {
   min-width: auto;
   width: auto;
   padding: 0;
}
