@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

html,
body {
  width: 100%;
  height: 100%;
  height: -webkit-fill-available;
}

html {
  display: flex;
  flex-direction: column;
  height: 100%;
}

body {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  font-family:
    'SoDo Sans',
    'Open Sans',
    -apple-system,
    '微軟正黑體',
    'Microsoft JhengHei',
    Helvetica,
    Arial,
    Sans-Serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  max-width: 1024px;
  margin: auto;
}

* {
  box-sizing: border-box;
  word-break: break-word;
  font-family:
    'SoDo Sans',
    'Open Sans',
    -apple-system,
    '微軟正黑體',
    'Microsoft JhengHei',
    Helvetica,
    Arial,
    Sans-Serif;
}

#root {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  /* flex-direction: column;
  flex: 1 0 auto; */
  min-height: 100%;
  width: 100%;
}
