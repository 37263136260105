.noRewardContentContainer {
      position: relative;
      padding: 32px;
      margin-top: calc( 50% - 125px);
   .imageWrapper {
      width: 100px;
      margin: auto;
      img {
         width: 100px;
      }
   }
   h3{
      text-align: center;
      margin-top: 16px;
      margin-bottom: 8px;
   }
   .noRewardDescription {
      font-weight: 400;
      text-align: center;
      color: var(--mui-palette-black-black038);
   }
  
}
.contentContainer {
   background-color: var(--mui-palette-customGrey-natural);
   &:global(.onTheWay) {
     padding-bottom: 134px;
   }
}
.contentWrapper {
   background-color: var(--mui-palette-white-opacity100);
   border-bottom: 1px solid #F0F0F0;
   padding: 16px;
   p {
      color: var(--mui-palette-black-black058);
   }
   ul {
      padding-inline-start: 0px;
      margin-block-start: 0em;
      margin-block-end: 0em;
      margin-inline-start: calc(1em + 3.5px);
   }
   .subTitle {
      margin-bottom: 16px;
      color: var(--mui-palette-black-black087);
   }
   .statusTag {
      border-radius: 10px;
      padding: 4px 6px;
      margin-left: auto;
      margin-right: 0px;
      font-size: 10px;
      font-weight: 400;
      line-height: 13px;


      &:global(.onTheWay) {
         color: var(--mui-palette-green-apron);
         border: 1px solid var(--mui-palette-green-apron);
      }
      &:global(.pending) {
         color: var(--mui-palette-warning-main);
         border: 1px solid var(--mui-palette-warning-main);
      }
      &:global(.collected) {
         color: var(--mui-palette-green-starbucks);
         border: 1px solid var(--mui-palette-green-starbucks);
      }
     
   }
   
}
.confirmBtnWrapper{
   width: 100%;
   position: fixed;
   padding: 12px 16px 36px 16px;
   bottom: 0px;
   background-color: var(--mui-palette-white-opacity100);
   box-shadow: 0px -2px 15px 0px #0000000D; 
   align-items: center;
   .confirmNotice{
      align-items: center;
      p {
        line-height: 15.54px;
        color: var(--mui-palette-green-starbucks);
      }
   }
}