.pageWrapper {
    padding: 16px;
    height: 100vh;
    // background-color: var(--mui-palette-customGrey-natural); 
    background-color: var(--mui-palette-background-default);
    .logo {
        max-width: 60px;
        max-height: 60px;
        margin-bottom: 30px;
    }    
    .text {
        margin-bottom: 30px;
        & h1 {
            font-size: 32px;
            font-weight: 400;
            line-height: 38px;
            letter-spacing: -0.011em;
            color: var(--mui-palette-green-house); 
        }
        & b {
            font-weight: 700;
            &:not(:last-child) {
                color: var(--mui-palette-primary-main);    
            }
        }
    }
    
    .formWrapper {
        .InputContainer {
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            margin-bottom: 30px;
            & :global(.MuiTextField-root) {
                width: 100%;
            } 
            & :global(.Mui-focused) {
                & fieldset {
                    border-color: var(--mui-palette-green-starbucks);
                }
            }
            & :global(.MuiFormHelperText-root) {
                margin-left: 0;
            }
        }
    }
    .functionContainer {
        display: flex;
        justify-content: space-between;
        .forgotPw {
            color: var(--mui-palette-green-accent);
            text-transform: none;
        }
    }
}
.introductionWrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1000;
    background: var(--mui-palette-white-opacity100);
    box-sizing: border-box;
    .introductionPage {
        display: flex;
        height: calc(100% - 98px);
        padding: 0px 27.5px 27.5px 27.5px;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        box-sizing: border-box;
        .pagination {
            display: flex;
            justify-content: center;
            margin-top: 36px;
            margin-bottom: 56px;
            column-gap: 8px;
            .dot {
                height: 5px;
                width: 5px;
                opacity: 30%;
                border-radius: 50%;
                background-color: var(--mui-palette-green-starbucks);
                transition: all 0.5s ease;
                &.active {
                    width: 15px;
                    opacity: 100%;
                    border-radius: 100px;
                }
            }
        }
        .title {
            color: var(--mui-palette-black-opacity87);
        }
        .description {
            color: var(--mui-palette-black-opacity38);
        }
    }
    .nextBtnContainer {
        position:fixed;
        width: 100%;
        box-sizing: border-box;
        bottom: 0;
        padding: 24px 16px;
        box-shadow: 0px -2px 15px 0px #0000000D;
    }

}
