.inputContainer {
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4375em;
  color: var(--mui-palette-text-primary);
  &.error {
    // color: var(--mui-palette-error-main);
    .inputWrapper:before {
      border-bottom-color: var(--mui-palette-error-main);
    }
    .inputWrapper:after {
      border-bottom-color: var(--mui-palette-error-main);
      // transform: scaleX(1) translateX(0)
    }
    .helperText {
      color: var(--mui-palette-error-main);
    }
    &.selector {
      .inputWrapper {
        .input {
          p {
            color: var(--mui-palette-error-main);
          }
        }
        &:before {
          border: 1px solid var(--mui-palette-error-main);
        }
      }
    }
  }

  &.selector {
    width: auto;
    .inputWrapper {
      padding: 4px;
      min-height: auto;
      width: fit-content;
      display: flex;
      align-items: center;
      column-gap: 4px;
      &:before {
        border: 1px solid var(--mui-palette-black-opacity26);
        width: 100%;
        height: 100%;
        border-radius: 100px;
        bottom: auto;
        left: auto;
      }
      &:after {
        border: none;
      }
      .input {
        padding: 0px;
        input {
          background-color: transparent;
          display: none;
        }
      }
    }
  }

  &.disabled {
    input:disabled,
    textarea:disabled,
    input[readonly],
    textarea[readonly] {
      background-color: transparent;
      opacity: 0.26;
    }
  }
}

.inputWrapper {
  width: 100%;
  min-height: 48px;
  position: relative;
  gap: 10px;

  &:before {
    border-bottom: 1px solid var(--mui-palette-customGrey-ceramic);
    left: 0;
    bottom: 0;
    content: '\00a0';
    position: absolute;
    right: 0;
    -webkit-transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1)
      0ms;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;
  }
  &:after {
    border-bottom: 2px solid var(--mui-palette-primary-main);
    left: 0;
    bottom: 0;
    content: '';
    position: absolute;
    right: 0;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    pointer-events: none;
  }
  &.focus:after {
    transform: scaleX(1) translateX(0);
  }
  .label {
    color: var(--mui-palette-black-opacity38);
    flex-shrink: unset;
  }
  .input {
    display: flex;
    align-items: center;
    padding: 8px 0;
    position: relative;
    input,
    textarea {
      width: 100%;
      // height: auto;
      padding: 0;
      outline: none;
      border: none;
      font: inherit;
      letter-spacing: inherit;
      color: inherit;
      resize: none;
      overflow: hidden;
      appearance: none;
      height: fit-content;
    }
    p {
      width: 100%;
      color: var(--mui-palette-black-black038);
      overflow: hidden;
      text-overflow: ellipsis;
      &.haveValue {
        color: var(--mui-palette-black-black087);
        &.disabled {
          color: var(--mui-palette-black-opacity26);
        }
      }
    }
  }
  .adornment {
    img {
      display: block;
    }
  }
  .chip {
    // background: #F9F9F9;
    border-radius: 5px;
    // color: #6B6B6B;
    span {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

.helperText {
  padding-top: 4px;
  color: var(--mui-palette-black-black026);
}
