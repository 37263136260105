.calendar {
  height: 100%;
  overflow: hidden;
  &Container {
    overflow: auto;
  }
  &Wrapper {
    background-color: var(--mui-palette-background-default);
    margin-bottom: 16px;
  }
  &TodayButton {
    width: auto !important;
    height: 28px !important;
  }
  &Tab {
    flex: 0 0 50%;
    max-width: 50%;
    min-height: auto;
    // font-size
    &Section {
      padding: 0 16px;
      min-height: auto;
      background-color: var(--mui-palette-background-default);
    }
  }
  & &Event {
    &List {
      width: 100%;
      padding: 0 16px;
    }
    &Date {
      padding: 12px 0;
      color: var(--mui-palette-black-black038);
    }
    &Item {
      display: flex;
      width: 100%;
      padding: 12px 16px;
      margin-bottom: 12px;
      align-items: center;
      gap: 24px;
      border-radius: 5px;
      border-left: 5px solid var(--mui-palette-green-apron);
      background: var(--mui-palette-background-default);
      box-shadow: 0px 4px 16px 0px var(--mui-palette-black-opacity04);
      .calenderIcon {
        img {
          width: 32px;
          height: auto;
        }
      }
      &Info {
        overflow: hidden;
        h5 {
          color: var(--mui-palette-black-black087);
        }
        p {
          // padding-top: 4px;
          color: var(--mui-palette-black-black026);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        * {
          background: none !important;
        }
      }
      &Time {
        padding-top: 4px;
        p {
          color: #6b6b6b;
        }
        .calendarDateItem + .calendarDateItem {
          margin-left: 8px;
          padding-left: 14px;
          position: relative;
          &:before {
            content: "";
            height: 1px;
            width: 6px;
            background-color: #6b6b6b;
            position: absolute;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
  & .calendarEventItem[data-list-type="msTeams"] {
    border-left-color: var(--mui-palette-msTeam-main);
  }
  &[data-display-mode="list"] {
    overflow: auto;
    .calendarEventList {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  &[data-list-type="msTeams"] {
    & button[data-today] {
      &[aria-selected="true"] {
        background-color: var(--mui-palette-msTeam-main);
      }
      + span {
        background-color: var(--mui-palette-msTeam-main);
      }
    }
    & .calendarEventItem {
      border-left-color: var(--mui-palette-msTeam-main);
    }
  }
}
.calendarEventItem {
  &.past {
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.35);
      z-index: 1;
    }
  }
}
.popup {
  &Header {
    gap: 10px;
    .backButton {
      padding: 0;
    }
  }
  &Container {
    height: 100%;
    overflow: hidden;
  }
  &Content {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.toolsContainer {
  .toolsItem {
    &:first-child {
      margin-left: -8px;
    }
    &:last-child {
      margin-right: -8px;
    }
  }
}
