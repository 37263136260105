#filePopup {
  z-index: 9999;
}

.popupHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    display: flex;
    align-items: center;
    img {
      width: 28px;
      margin-right: 8px;
    }
  }
}

.contentList {
  button {
    all: unset;
    padding: 16px;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 14px;
    color: var(--mui-palette-black-black087);
    position: relative;
    img {
      margin-right: 12px;
    }
    input[type="file"] {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
}

.contentInput {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .inputControl {
    flex: auto;
    position: relative;
    button {
      all: unset;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      line-height: 0;
    }
  }
  input[type="text"] {
    all: unset;
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    border-bottom: 1px solid #edebe9;
    color: var(--mui-palette-black-black087);
    padding: 16px 0;
    font-weight: 400;
    font-size: 14px;
    &::placeholder {
      color: var(--mui-palette-black-black087);
      opacity: 0.4;
    }
  }
  .extension {
    font-weight: 400;
    font-size: 14px;
    color: #000;
    margin-left: 12px;
  }
}

.inputAction {
  display: flex;
  padding: 16px;
  gap: 11px;
  .cancel {
    border: 1px solid #00754a !important;
    color: #00754a !important;
    background-color: #fff !important;
  }
  .remove {
    border: 1px solid #c82014 !important;
    background-color: #c82014 !important;
  }
}

.contentDetails {
  .detailsHeader {
    padding: 16px;
    border-bottom: 8px solid #f9f9f9;
    .title {
      font-weight: 600;
      font-size: 16px;
      color: var(--mui-palette-black-black087);
      display: flex;
      align-items: center;
      img {
        width: 28px;
        margin-right: 8px;
      }
    }
    .mediaContent {
      border: 1px solid #f0f0f0;
      border-radius: 14px;
      margin-bottom: 12px;
      overflow: hidden;
      line-height: 0;
      position: relative;
      img,
      video {
        width: 100%;
      }
      .playButton {
        width: 50px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .detailsContent {
    padding: 16px;
    * {
      all: unset;
      display: block;
      box-sizing: border-box;
    }
    h5 {
      font-weight: 600;
      font-size: 14px;
      color: var(--mui-palette-black-black058);
      margin-bottom: 24px;
    }
    ul {
      li {
        margin-bottom: 24px;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        label {
          color: var(--mui-palette-black-black038);
        }
        span {
          color: var(--mui-palette-black-black087);
          display: flex;
          align-items: center;
          text-transform: capitalize;
        }
        .location {
          display: flex;
          align-items: center;
          span + span {
            &::before {
              content: "";
              position: relative;
              background-image: url("~/public/assets/images/chevron_right_black.svg");
              display: inline-block;
              width: 16px;
              height: 16px;
              background-repeat: no-repeat;
              background-position: center;
              background-size: 6px;
              margin: 0 2px;
            }
          }
        }
      }
    }
  }
}

.contentDesc {
  padding: 24px 16px;
  span {
    font-weight: 400;
    font-size: 14px;
    color: var(--mui-palette-black-black038);
  }
}
