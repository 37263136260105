.tabContainer {
   width: 100%;
   // overflow-x: hidden;
   background-color: var(--mui-palette-white-opacity100);
   & :global(.MuiTabs-scroller ) {
      overflow-x: scroll !important;text-transform: none;
      &::-webkit-scrollbar {
         display: none;
      }
      & :global(.MuiTab-root){
         text-transform: none;
         font-size: 14px;
         font-weight: 400;
         line-height: 17px;
         padding: 12px 16px;
      }
   }
}