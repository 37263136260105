#header {
   padding: 0 16px;
   background-color: var(--mui-palette-white-opacity100); 
   color: var(--mui-palette-black-opacity100);
   box-shadow: none;
   border-bottom: 1px solid var(--mui-palette-customGrey-natural); 
   &.noBorder{
      border-bottom: none;
   }
   &.green{
      background-color: transparent;
      position: relative;
      .title {
         color: var(--mui-palette-white-opacity100); 
      }
   }
   .title {
      padding: 12px 0;
   }
   .toolsActions {
      img {
         width: 28px;
      }
      :global(.active) {
         ::after {
            content: "";
            position: absolute;
            display: inline-block;
            vertical-align: middle;
            width: 6px;
            height: 6px;
            background-color: var(--mui-palette-green-apron);
            border-radius: 100%;
            /* margin-left: 4px; */
            top: 3px;
            right: 3px;
         }
       
      }
      
     
   }
   :global(.MuiTypography-button) {
      &:disabled {
         color: var(--mui-palette-black-black026);
      }
     
   }  
}


#backButton {
   padding: 11px 0;
   img {
      width: 28px;
   } 
}