.dateTimeFieldSection {
  padding-top: 12px;
  padding-bottom: 12px;
  .dateTimeField {
    padding: 8px 16px;
  }
  .dateTimeError {
    color: var(--mui-palette-error-main);
  }
}

button.addEndDateButton {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
}

button.dateButton {
  display: block;
  padding: 8px;
  border: none;
  border-radius: 4px;
  background-color: var(--mui-palette-black-opacity06);
  color: var(--mui-palette-black-opacity87);
  text-decoration: none;
  &.active {
    background-color: var(--mui-palette-green-light);
    color: var(--mui-palette-green-starbucks);
  };
  &:disabled {
    background-color: var(--mui-palette-black-opacity26);
    color: var(--mui-palette-white-opacity100);
  };
}

.timePickerContainer {
  padding: 16px;
  gap: 24px;
  p {
    color: var(--mui-palette-black-black087);
  }
}

.timePickerWrapper {
  position: relative;
  :global(.picker-container) {
    :global(.picker-inner) {
      padding: 0 20px;
      -webkit-mask-box-image: none;
    }
    :global(.picker-column) {
      max-width: 80px;
      :global(.picker-item) {
        color: var(--mui-palette-black-black026);
        font-size: 14px;
        &:global(.picker-item-selected) {
          color: var(--mui-palette-primary-main);
          font-size: 16px;
        }
      }
    }
    :global(.picker-highlight) {
      &:after,
      &:before {
        background-color: var(--mui-palette-black-black026);;
      }
    }
  }
}