#receiveCouponPopup {
  :global(.MuiPaper-root.MuiDialog-paper) {
    overflow: visible;
  }
  :global(.MuiTypography-root.MuiDialogTitle-root) {
    border: 0;
    height: 80px;
  }
  .header {
    position: absolute;
    left: 50%;
    bottom: calc(100% - 85px);
    transform: translateX(-50%);
  }
  .content {
    padding: 16px;
  }
  .title {
    font-size: 18px;
    font-weight: 600;
    color: var(--mui-palette-black-black087);
    margin-bottom: 8px;
  }
  .text {
    color: var(--mui-palette-black-black038);
    font-size: 14px;
    margin-bottom: 48px;
  }
}
