.roster {
  &Container {
    overflow: auto;
    background-color: var(--mui-palette-background-default);
  }
  &CurrentStore {
    padding: 16px;
    gap: 24px;
    background-color: var(--mui-palette-background-default);
    color: var(--mui-palette-black-black058);
    margin-bottom: 5px;
    &Info {
      gap: 12px;
      color: var(--mui-palette-black-black038);
      p {
        padding: 2px 0;
      }
    }
    &Icon {

    }
    &Button {
      padding: 0;
      img {
        width: 24px;
      }
    }
  }
  &Title {
    padding: 16px;
    padding-bottom: 4px;
    background-color: var(--mui-palette-background-default);
    color: var(--mui-palette-black-black058);
  }
  &List {
    & &Item {
      padding: 16px;
      gap: 24px;
    }
  }
}

.storePopup {
  &Header {
    gap: 10px;
  }
  &CloseButton {
    padding: 0 !important;
    img {
      width: 28px;
    }
  }
}