.itemWrapper{
   display: flex;
   // padding: 16px;
   column-gap: 12px;
   align-items: center;
   .profileIcon {
      // width: 40px;
      // height: 40px;
      // border-radius: 50%;
      border: 1px solid var(--mui-palette-white-opacity100); 
      // background-size: cover;
      // background-position: center;
      // position: relative;
   }  
   .label {
      color:#9E9E9E;
      margin-bottom: 8px;
   }  

   .menuItemDetail {
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 4px;
      width: 100%;
      .description {
         margin-left: 0;
         margin-right: auto;
      }
      .remarks {
         align-self: flex-end;
         color: var(--mui-palette-black-black038);
      }
   }

   

}