.eventResultCard {
    position: relative;
    // padding: 16px 0px;
    padding: 8px 16px;
    gap: 16px;
    .eventResultImage {
       width: 122px;
       height: 72px;
       border-radius: 3px;
       overflow: hidden;
       img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
       }
    }
    .eventResultContent {
        padding: 8px;
        background-color: var(--mui-palette-customGrey-natural);
       .eventResultCategory {
          width: fit-content;
       }
       .eventResultTitle {}
       .eventResultDate {
          color: #6B6B6B;
          li {
             font-size: 12px;
             line-height: 16px;
             &[aria-hidden=true] {
                margin: 0 4px;
             }
          }
       }
       .eventResultLocation {
          gap: 4px;
          color: #6B6B6B;
          img {
             width: 14px;
          }
       }
    }
    .deleteEventBtn{
       position: absolute;
       top: 10px;
       right: 10px;
       img{
          width: 24px;
       }
    }
 }