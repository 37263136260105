.swiperContainer {
    :global(.swiper) {
        overflow: visible;
        :global(.swiper-wrapper) {
            // align-items: center;
        }
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    :global(.swiper-slide) {
        height: auto;
    }
    .swiper {
        padding-bottom: 20px;
    }
    .autoWidthSlide {
        width: auto;
    }
} 

.pagination {
    position: relative;
    z-index: 1;
    text-align: center;
    :global(.swiper-pagination-bullet-active) {
        background: var(--mui-palette-primary-main);
    }
}
