.attachmentContainer {
  display: flex;
  flex-direction: column !important;
  overflow: auto;
}

.attachmentList {
  padding: 8px;
  .attachmentItem {
    padding: 8px;
  }
  .attachmentMedia {
    padding-top: 100%;
    position: relative;
    background-color: var(--mui-palette-background-default);
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
    video {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 60%;
      object-fit: cover;
      display: block;
    }
  }
  .attachmentName {
    word-break: break-all;
  }
}
