.root {
  height: 100%;
  position: relative;
  .container {
    width: 100%;
    min-height: 100%;
    padding: 24px 16px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .warningBar {
    position: absolute;
  }
}

.header {
  padding-bottom: 16px;
}

.chip {
  &Section {
    padding-bottom: 24px;
    color: var(--mui-palette-black-black038);
    &:last-child {
      padding-bottom: 0;
    }
    .chipFreeText {
      background-color: var(--mui-palette-green-accent);
      color: var(--mui-palette-white-opacity100)
    }
  }
  &Title {
    width: 100%;
    padding-bottom: 8px;
  }
  &List {
    gap: 8px;
  }
}

.freeTextPopupContent {
  padding: 16px;
  padding-bottom: 0;
}
