.root {
   width: 100%;
   position: relative;
}

.calendarContainer {
   .calendarArrow {
      color: var(--mui-palette-green-accent);
   }
   .calendarSkeleton {
      width: 100%;
      & > div {
         width: 100%;
         justify-content: space-between;
      }
   }
   .calendarDay {
      position: relative;
      padding: 5px;
      flex-shrink: 0;
      .event {
         position: absolute;
         left: calc(50% - 2px);
         bottom: -1px;
         width: 4px;
         height: 4px;
         border-radius: 4px;
         background-color: var(--mui-palette-green-apron);
      }
   }
}

.monthCalendar {
   &Container {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 16px;
      width: 100%;
      background-color: var(--mui-palette-background-default);
   }
   &Wrapper {
      width: 100%;
      height: 208px;
      position: relative;
      :global(.picker-container) {
      :global(.picker-inner) {
         padding: 0 20px;
         -webkit-mask-box-image: none;
      }
      :global(.picker-column) {
         max-width: 120px;
         :global(.picker-item) {
            color: var(--mui-palette-black-black026);
            font-size: 14px;
            &:global(.picker-item-selected) {
            color: var(--mui-palette-primary-main);
            font-size: 16px;
            }
         }
      }
      :global(.picker-highlight) {
         &:after,
         &:before {
            background-color: var(--mui-palette-black-black026);;
         }
      }
      }
   }
}
