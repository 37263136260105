.eventContainer {
  // display: flex;
  // flex-direction: column !important;
  overflow: auto;
  padding-bottom: 80px;
  .eventCalendar {
    width: 100%;
    background-color: var(--mui-palette-white-opacity100);
    box-shadow: 0px 1px 1px var(--mui-palette-black-opacity04);
    margin-bottom: 16px;
  }
  .eventList {
    width: 100%;
    background-color: var(--mui-palette-white-opacity100);
    padding: 16px;
  }
}

.groupName {
  padding-top: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  font-weight: 400;
  color: var(--mui-palette-black-black058) !important;
  text-decoration: none !important;
  img {
    width: 12px;
    height: 12px;
  }
}
