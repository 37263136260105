.pollResultContainer {
  background-color: var(--mui-palette-white-opacity100);
  .numberOfVotes {
    display: flex;
    align-items: center;
    padding: 16px;
    column-gap: 11px;
  }
  .detailsContainer {
    padding: 16px;
    .itemWrapper {
      margin-bottom: 24px;
      .label {
        color: #9e9e9e;
        margin-bottom: 8px;
      }
      .menuItem {
        padding: 16px 0px;
        .precentageBar {
          height: 16px;
          background-color: var(--mui-palette-green-apron);
          border-radius: 5px;
          margin-bottom: 8px;
        }
        .menuItemDetail {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .option {
            color: #212121;
            margin-left: 0;
            margin-right: auto;
          }
          span {
            font-weight: 700;
            margin-left: 6px;
            color: #6b6b6b;
          }
          img {
            margin-left: 12.75px;
          }
          .imageContainer {
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            background: #f0f0f0;
            margin-right: 8px;
          }
          .img {
            width: 24px;
            height: 24px;
            margin-left: 0;
            &.voteImage {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
      hr {
        border-color: var(--mui-palette-black-opacity06);
      }
    }
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
