.eventTabsList {
   width: 100%;
   padding: 12px 10px;
   background-color: var(--mui-palette-white-opacity100);
   .eventTab {
      padding: 6px;
      text-align: center;
      button {
         display: block;
         width: 100%;
         padding: 8px;
         background-color: var(--mui-palette-customGrey-seashell);
         border: none;
         border-radius: 10px;
         color: var(--mui-palette-black-black058);
         &.active {
            box-shadow: 0px 4px 4px var(--mui-palette-black-opacity08);
            background-color: var(--mui-palette-primary-main);
            color: var(--mui-palette-white-opacity100);
         }
         .eventCount {
            padding-top: 8px;
         }
      }
   }
}