.cardWrapper {
    box-shadow: none!important;
    padding: 0px;
    &.cardSize {
        &--small {
            &.skeleton{
                width: 65%;
            }
        }
        &--large {
            padding-top: 16px;
            border-bottom: 1px solid var(--mui-palette-customGrey-seashell);
            &:first-child {
                padding-top: 0;
            }
            .cardThumbnail {
                padding-top: calc(100% / 218 * 131);
            }
            .dateContainer {
                margin: 16px 14px;
            }
            .cardContent {
                padding-top: 16px;
                padding-bottom: 16px;
                .eventDetails {
                    .timeContainer {
                        margin-top: 10px;
                        img {
                            width: 20px;
                        }
                    }
                }
            }
            .statusTag {
                margin-top: 10px;
            }
        }
    }
    .cardThumbnail {
        width: 100%;
        padding-top: calc(100% / 218 * 131);
        border-radius: 4px;
        position: relative;
    }
    .dateContainer {
        position: absolute;
        top: 0;
        margin: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--mui-palette-green-accentOpacity50);
        padding: 4px 9px;
        border-radius: 2px;
        color: var(--mui-palette-white-opacity100); 
        .date {
            line-height: 1;
        }
        .month {}
    }
    .typeTag{
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .cardContent {
        padding: 0;
        padding-top: 8px;
        .eventDetails {
            .eventTitle{
                color: var(--mui-palette-black-opacity87); 
            }
            .timeContainer {
                margin-top: 4px;
                color: var(--mui-palette-black-opacity58); 
                img {
                    display: block;
                    width: 16px;
                }
            }
        }
        .caption {
            margin-bottom: 4px;
        }
    }
    .statusTag{
        padding: 5px 10px;
        color: var(--mui-palette-green-starbucks);
        border-radius: 4px;
        margin-top: 8px;
        width: fit-content;
        &[data-status=open] {
            background-color: var(--mui-palette-green-light);
            color: var(--mui-palette-green-starbucks);
        }
        &[data-status=upcoming] {
            background-color: var(--mui-palette-customGrey-champagne);
            color: var(--mui-palette-gold-rewards30black);
        }
        &[data-status=registered] {
            background-color: var(--mui-palette-primary-main);
            color: var(--mui-palette-white-opacity100);
        }
        &[data-status=ended] {
            background-color: var(--mui-palette-Alert-errorStandardBg);
            color: var(--mui-palette-error-main);
        }
        &[data-status=fulled] {
            background-color: var(--mui-palette-customGrey-cornsilk);
            color: var(--mui-palette-gold-urobilin);
        }
    }
}