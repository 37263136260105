.container {
  background-color: #fff;
  padding-bottom: 50px;
  height: 100%;
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 200px);
  .noDataContainer {
    text-align: center;
  }
}
