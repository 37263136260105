.pageWrapper {
  background-color: #fff;
}
.headerSection {
  width: 100%;
  position: relative;
  // z-index: 1;
  box-sizing: border-box;
  background-image: url('~/public/assets/images/BG.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 16px 16px 38px 16px;
  text-align: left;
  height: 183px;
  .profileIcon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid var(--mui-palette-white-opacity100);
    background-size: cover;
    background-position: center;
    margin-bottom: 16px;
    &.hkStyle {
      width: 36px;
      height: 36px;
    }
  }
  .infoContainer {
    &.hkStyle {
      display: flex;
      gap: 6px;
      .greetingTitle {
        height: 36px;
        line-height: 36px;
        font-size: 16px;
        width: calc(100vw - 200px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .greetingCaption {
        display: none;
      }
    }
  }
  .greetingTitle {
    color: var(--mui-palette-white-opacity100);
    margin-bottom: 4px;
  }
  .greetingCaption {
    color: var(--mui-palette-white-opacity70);
  }
  .bgLogo {
    position: absolute;
    top: 0;
    right: 0;
    // z-index: -1;
  }
}
.mainSection {
  width: 100%;
  position: relative;
  // z-index: 2;
  //   margin-top: -16px;
  // border-top-left-radius: 16px;
  background-color: var(--mui-palette-white-opacity100);
  overflow-x: hidden;
  padding-bottom: 65px;
}

.swiperContainer {
  flex: none;
  margin-top: -72px;
  div:global(.swiper) {
    width: 100%;
    height: auto;
  }
  &.hkStyle {
    margin-top: -130px;
    div:global(.swiper-slide) {
      width: 346px;
      .swiperItem {
        aspect-ratio: 346 / 194;
      }
    }
  }
  div:global(.swiper-slide) {
    width: 328px;
    display: flex;
    justify-content: center;
    align-items: center;
    .swiperItem {
      width: 100%;

      margin: 16px 0;
      border-radius: 4px;
      overflow: hidden;
      transition: all 200ms linear;
      transform: scale((118/144));
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:global(.swiper-slide-active) {
      .swiperItem {
        box-shadow: 0px 4px 10px var(--mui-palette-black-opacity20);
        transform: scale(1);
      }
    }
  }

  div:global(.swiper-pagination-fraction) {
    position: static;
    padding-bottom: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: var(--mui-palette-black-black058);
    :global(.swiper-pagination-current) {
      font-weight: 600;
      color: var(--mui-palette-black-black087);
    }
  }
}
