.ChatApp {
  height: calc(100vh - 63px);
  // width: 100vw;
  :global(.sendbird-channel-header),
  :global(.sendbird-channel-list) {
    width: 100%;
  }
  :global(
      .sendbird-channel-header
        .sendbird-channel-header__title
        .sendbird-channel-header__title__right
    ) {
    display: flex;
    align-items: center;
    padding-top: 0;
  }
  :global(.sendbird-channel-header__title__right__name.sendbird-label) {
    font-size: 20px;
  }
  :global(.sendbird-channel-header__title__right__user-id) {
    display: none !important;
  }
  :global(.sendbird-message-content) {
    &:global(.incoming),
    &:global(.outgoing) {
      :global(.sendbird-message-content__middle) {
        max-width: calc(100% - 100px);
        :global(.sendbird-message-content__middle__body-container) {
          width: 100%;
        }
      }
    }
  }

  :global(.sendbird-og-message-item-body) {
    min-width: initial;
    max-width: initial;
  }

  :global(.sendbird-app__settingspanel-wrap),
  :global(.sendbird-message-search-pannel) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  :global(.sendbird-channel-settings__freeze) {
    max-width: none;
  }
  :global(.sendbird-channel-settings__frozen-icon) {
    input {
      opacity: 0;
    }
    :global(.sendbird-input-toggle-button) {
      display: block;
      position: relative;
      &:global(.sendbird-input-toggle-button--unchecked) {
        background-color: #bdbdbd;
      }
      &:global(.sendbird-input-toggle-button--checked) {
        background-color: #00754a;
      }
      :global(.sendbird-input-toggle-button__inner-dot) {
        background-color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        transition: all 0.5s;
        &:global(.sendbird-input-toggle-button__inner-dot--inactivate) {
          left: 5px;
        }
        &:global(.sendbird-input-toggle-button__inner-dot--activate) {
          left: 23px;
        }
      }
    }
  }
  :global(.sendbird-channel-settings) {
    width: 100%;
  }
  :global(.sendbird-channel-preview) {
    width: 100%;

    :global(.sendbird-channel-preview__content),
    :global(.sendbird-channel-preview__content__upper) {
      width: 100%;
    }
  }

  :global(.sendbird-channel-preview__content__upper__header__total-members) {
    display: none;
  }

  :global(.sendbird-conversation) {
    :global(.sendbird-conversation__messages-padding) {
      :global(.sendbird-message-content__middle__message-item-body) {
        &:global(.sendbird-thumbnail-message-item-body) {
          min-width: calc(360px * 0.6) !important;
          height: calc(270px * 0.6) !important;
        }
        :global(.sendbird-image-renderer__image),
        :global(.sendbird-thumbnail-message-item-body__thumbnail) {
          min-width: calc(360px * 0.6) !important;
          height: calc(270px * 0.6) !important;
        }
      }
    }
    :global(.sendbird-emoji-reactions) {
      align-items: center;
      :global(.sendbird-emoji-reactions__reaction-badge) {
        margin-bottom: 0;
      }
    }
    :global(
        .sendbird-reaction-badge__inner .sendbird-reaction-badge__inner__icon
      ) {
      top: 1px;
    }
  }
}
.disableFileSharing {
  :global(.sendbird-message-input .sendbird-message-input--attach) {
    display: none;
  }
}
.chatHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  margin: 0;

  button {
    padding: 5px;
    border: none;
    background: transparent;
    line-height: 0;
  }

  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > button {
      margin-right: 13px;
    }
    .chatThumbnail {
      width: 32px;
      height: 32px;
      margin-right: 5.5px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      img {
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    span {
      font-size: 18px;
      font-weight: 700;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 47vw;
    }
  }
  .right {
    display: flex;
    gap: 5px;
  }
}
:global(.sendbird-modal) {
  width: 100%;
  z-index: 9999;
  :global(.sendbird-modal__content) {
    padding: 0;
    width: calc(100% - 32px);
    height: auto;
    :global(.sendbird-modal__header) {
      padding: 18px 0;
      width: calc(100% - 48px);
      margin: auto;
    }
    :global(.sendbird-modal__body) {
      :global(.sendbird-label.sendbird-label--caption-1) {
        width: 100%;
        display: block;
        padding: 20px 24px;
        padding-top: 0;
      }
      :global(.sendbird-user-list-item__avatar) {
        left: 24px;
      }
      :global(.sendbird-user-list-item__title) {
        left: 75px;
      }
    }
    :global(.sendbird-modal__footer) {
      padding: 0 24px;
      padding-bottom: 24px;
    }
  }
  &:global(.sendbird-add-channel__modal) {
    :global(.sendbird-modal__content) {
      width: calc(100% - 55px);
      height: auto;
      :global(.sendbird-modal__header),
      :global(.sendbird-modal__body) {
        width: calc(100% - 48px);
        margin: auto;
      }
      :global(.sendbird-modal__header) {
        padding-top: 18px;
      }
      :global(.sendbird-modal__body) {
        margin-bottom: 24px;
      }
    }
  }
}

:global(
    .sendbird-theme--light
      .sendbird-checkbox
      .sendbird-checkbox--checkmark:after
  ) {
  top: 0px;
  width: 3px;
  height: 10px;
}

:global(.sendbird-checkbox.disabled) {
  display: none;
}

:global(.sendbird-add-channel__rectangle),
:global(.sendbird-user-list-item) {
  width: 100%;
}

:global(.sendbird-create-channel--scroll) {
  height: calc(100vh - 250px);
}

:global(.sendbird-more-members__popup-scroll) {
  height: calc(100vh - 250px);
  max-height: none;
}

.channelList {
  display: flex;
  :global(.sendbird-message-status) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    :global(.sendbird-message-status__icon) {
      top: 0;
    }
    :global(.sendbird-message-status__text) {
      line-height: normal;
    }
  }
}
:global(
    .sendbird-channel-preview
      .sendbird-channel-preview__content
      .sendbird-channel-preview__content__upper
      .sendbird-channel-preview__content__upper__header
  ) {
  flex: 0 0 auto;
  max-width: 60%;
}
:global(
    .sendbird-modal
      .sendbird-modal__content
      .sendbird-modal__body
      .sendbird-label.sendbird-label--caption-1
  ) {
  display: none;
}

:global(.sendbird-accordion .sendbird-accordion) {
  border-bottom: 0;
}
:global(.sendbird-channel-profile .sendbird-channel-profile__title) {
  max-width: none;
}

:global(.channel-profile-form),
:global(.sendbird-edit-user-profile) {
  padding: 20px;
}

:global(.channel-profile-form .channel-profile-form__img-section),
:global(.sendbird-edit-user-profile__img) {
  text-align: center;
}
:global(.channel-profile-form .channel-profile-form__avatar-button),
:global(.sendbird-edit-user-profile__img__avatar-button) {
  position: relative;
  top: unset;
  left: unset;
}

.avatar {
  height: 80px;
  width: 80px;
  .renderer {
    height: 100%;
    width: 100%;
    img {
      max-width: 100%;
    }
  }
  .defaultIcon {
    height: 100%;
    width: 100%;
    svg {
      width: 46px;
      height: 46px;
      :global(.icon-user_svg__fill) {
        fill: #fff;
      }
    }
  }
}
:global(.sendbird-icon-arrow-left) {
  margin-right: 8px;
}

:global(.sendbird-bottomsheet) {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

:global(.sendbird-bottomsheet__content) {
  z-index: 10001;
  position: fixed;
  bottom: 0;
  left: 0;
}

:global(.sendbird-bottomsheet__backdrop) {
  position: fixed;
  z-index: 10000;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

:global(.sendbird-message__bottomsheet) {
  width: 100vw;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 16px 0;
  text-align: center;
  > div + div {
    margin-left: 5px;
  }
}

:global(.sendbird-theme--light .sendbird-message__bottomsheet) {
  background-color: var(--sendbird-light-background-50);
}

:global(.sendbird-theme--dark .sendbird-message__bottomsheet) {
  background-color: var(--sendbird-dark-background-600);
}

:global(.sendbird-theme--light .sendbird-bottomsheet__backdrop) {
  background-color: var(--sendbird-light-overlay-02);
}

:global(.sendbird-theme--dark .sendbird-bottomsheet__backdrop) {
  background-color: var(--sendbird-dark-overlay-02);
}

:global(.sendbird-message__bottomsheet-reaction-bar) {
  padding-left: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  margin-top: 0;
}

:global(.sendbird-message__bottomsheet-reaction-bar__row) {
  display: inline-block;
}

:global(
    .sendbird-message__bottomsheet-reaction-bar__row.sendbird-message__bottomsheet-reaction-bar__all
  ) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

:global(.sendbird-message__bottomsheet--action) {
  cursor: pointer;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
}

:global(.sendbird-message__bottomsheet--action .sendbird-icon) {
  margin-right: 24px;
}

:global(.sendbird-message__bottomsheet--action-disabled) {
  cursor: not-allowed;
}

:global(.sendbird-edit-user-profile__theme) {
  display: none;
}

:global(.sendbird-badge) {
  line-height: 18px;
}

:global(.sendbird-message-input .sendbird-message-input--attach) {
  bottom: 50%;
  transform: translateY(50%);
}

:global(
    .sendbird-thumbnail-message-item-body
      .sendbird-thumbnail-message-item-body__icon-wrapper
  ) {
  justify-content: flex-end;
  padding-right: 5px;
  padding-top: 10px;
}
:global(
    .sendbird-thumbnail-message-item-body
      .sendbird-thumbnail-message-item-body__icon-wrapper
      .sendbird-thumbnail-message-item-body__icon-wrapper__icon
  ) {
  width: 30px;
  height: 30px;
}
:global(.sendbird-icon) {
  &:global(.sendbird-icon-gif),
  &:global(.sendbird-icon-play) {
    width: 20px !important;
    min-width: 20px !important;
    height: 20px !important;
    min-height: 20px !important;
  }
}

// :global(.sendbird-theme--light .sendbird-thumbnail-message-item-body) {
//   background-color: #000;
// }

:global(
    .sendbird-thumbnail-message-item-body
      .sendbird-thumbnail-message-item-body__video
  ) {
  width: auto;
  height: 100%;
  border-top: 15px solid #000;
  border-bottom: 15px solid #000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

:global(
    .sendbird-fileviewer
      .sendbird-fileviewer__header
      .sendbird-fileviewer__header__left
  ) {
  padding: 16px;
}

:global(
    .sendbird-fileviewer
      .sendbird-fileviewer__header
      .sendbird-fileviewer__header__left
      .sendbird-fileviewer__header__left__filename
  ) {
  max-width: 28vw;
}

:global(.sendbird-fileviewer__header__right__actions__download),
:global(.sendbird-fileviewer__header__right__actions__delete),
:global(.sendbird-fileviewer__header__right__actions__close) {
  width: 45px;
  padding: 21px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
:global(
    .sendbird-channel-preview
      .sendbird-channel-preview__content
      .sendbird-channel-preview__content__lower
  ) {
  width: 100%;
}

:global(.sendbird-mention-suggest-list) {
  padding: 0px 24px;
  :global(.sendbird-mention-suggest-list__user-item) {
    display: grid;
    align-items: center;
    padding: 8px 16px;
    justify-items: stretch;
    grid-template-columns: 38px auto auto;
    :global(.sendbird-mention-suggest-list__user-item__user-id) {
      justify-self: end;
    }
  }
}

:global(.sendbird-emoji-reactions) {
  display: block;
  :global(.sendbird-emoji-reactions__reaction-badge) {
    margin-bottom: 3px !important;
  }
  :global(.sendbird-emoji-reactions__add-reaction-badge) {
    vertical-align: text-top;
  }
}
