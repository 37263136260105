.toggleButton {
  padding: 0 !important;
  width: 51px !important;
  height: 30px !important;
  //   display: inline-block;
  * {
    padding: 0 !important;
  }
  :global(.MuiSwitch-switchBase) {
    top: 2px;
    left: 2px;
    &:global(.Mui-checked) {
      color: #fff;
      + :global(.MuiSwitch-track) {
        background-color: var(--mui-palette-green-starbucks);
        opacity: 1;
      }
    }
  }
  :global(.MuiSwitch-input) {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    top: 0px;
    left: 0px !important;
    opacity: 0;
    z-index: 1;
  }
  :global(.MuiSwitch-thumb) {
    width: 27px;
    height: 27px;
  }
  :global(.MuiSwitch-track) {
    background: var(--mui-palette-black-opacity12);
    border-radius: 27px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 1;
  }
}
