.sectionContainer {
  width: 100%;
  padding-bottom: 65px;
  .sectionWrapper {
    .sectionHeader {
      display: flex;
      justify-content: space-between;
      padding: 0px 16px;
      .sectionTitle {
        color: var(--mui-palette-green-house);
        font-weight: 700;
      }
    }
  }
  .eventTabsList {
    width: 100%;
    padding: 12px 16px;
    background-color: var(--mui-palette-white-opacity100);
    .eventTab {
      padding: 6px;
      text-align: center;
      button {
        display: block;
        width: 100%;
        padding: 8px;
        background-color: var(--mui-palette-customGrey-seashell);
        border: none;
        border-radius: 10px;
        color: rgba(0, 0, 0, 0.06);
        &.active {
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
          background-color: #006241;
          color: var(--mui-palette-white-opacity100);
        }
        .eventCount {
          padding-top: 8px;
        }
      }
    }
  }
}
.createPostContainer {
  padding: 8px 16px;
  background-color: var(--mui-palette-white-opacity100);
  p {
    color: var(--mui-palette-black-opacity26);
  }
}
.postContainer {
  display: flex;
  flex-direction: column !important;
  overflow: auto;
  padding-bottom: 65px;

  .postList {
    display: flex;
    flex-direction: column !important;
    //  overflow: auto;
    height: 100%;
    width: 100%;
    background-color: var(--mui-palette-customGrey-natural);
  }
}

.calendarContainer {
  width: 100% !important;
  max-height: none !important;
  padding: 16px;
  & > div {
    margin: 0;
    &:first-child {
      padding: 0;
      padding-bottom: 16px;
      margin-right: -12px;
    }
  }
  div[role="row"] {
    width: 100%;
    justify-content: space-between;
    span[aria-label] {
      color: rgba(107, 107, 107, 0.5);
      font-weight: 400;
      line-height: 13px;
      font-size: 0;
      &:after {
        content: "vs";
        font-size: 11px;
      }
    }
  }
  .calendarDay {
    position: relative;
    padding: 5px;
    // margin: 0 2px;
    flex-shrink: 0;
    .days {
      width: 24px;
      height: 24px;
      // margin: 0;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #212121;
      &:hover,
      &[aria-selected="true"] {
        will-change: unset;
        background-color: transparent;
      }
      &[data-today="true"] {
        border: none;
        background-color: #006241;
        color: #ffffff;
        &:hover,
        &[aria-selected="true"] {
          will-change: unset;
          background-color: #006241;
        }
      }
    }
    .event {
      position: absolute;
      left: calc(50% - 2px);
      bottom: -1px;
      width: 4px;
      height: 4px;
      border-radius: 4px;
      background-color: #00a862;
    }
  }
}

#header {
  padding: 12px 16px;
  background-color: var(--mui-palette-white-opacity100);
  color: var(--mui-palette-black-opacity100);
  box-shadow: none;
}

.popupTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tabWrapper {
  position: sticky;
  top: 0;
  margin: 0;
  z-index: 1000;
}

.alertMsg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .alertBtn {
    font-weight: 600;
    min-width: max-content;
  }
}
