.pageWrapper {
  background-color: var(--mui-palette-white-opacity100);
  .sectionContainer {
    .sectionTitle {
      font-weight: 700;
      color: var(--mui-palette-green-house);
      text-transform: uppercase;
      padding: 16px;
    }
    .itemContainer {
      padding: 16px 10px;
      display: Grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-row-gap: 32px;
      grid-column-gap: 0px;
      .itemWrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        row-gap: 8px;
        color: var(--mui-palette-black-opacity87);
        img {
          max-width: 26px;
        }
      }
    }
  }
}
