.postCommentContainer {
  background-color: var(--mui-palette-white-opacity100);
  .commentField {
    padding: 12px 16px;
    display: flex;
    // align-items: center; //when not focus
    align-items: start;
    textarea {
      resize: none;
      width: 100%;
      font-size: 14px !important;
      padding: 8px;
      margin: 0px !important;
      line-height: 21px;
      border-radius: 5px;
      border-color: var(--mui-palette-black-opacity06);
      caret-color: var(--mui-palette-green-starbucks);
      ::placeholder {
        color: var(--mui-palette-black-opacity26);
      }
      &:focus {
        outline: none;
      }
    }
    .postButton {
      font-size: 14px;
      line-height: 17px;
      text-transform: none;
      padding: 0px 0px 0px 8px;
      align-self: flex-start;
      width: fit-content;
      min-width: 45px;
      margin-top: 10px;
    }
  }
  .postCommentForm {
    margin-left: 8px;
    width: 100%;
  }
  .suggestedWordContainer {
    position: fixed;
    left: 16px;
    height: 200px;
    bottom: 0vh;
    // margin: 0px 16px;
    width: calc(100% - 32px);
    overflow: auto;
    border-top: 1px solid var(--mui-palette-black-opacity06);
    background-color: white;
    z-index: 1000;
    .suggestedWord {
      padding: 8px 0px;
    }
  }
  .commentFieldOptions {
    margin-top: 4px;
  }

  .commentContainer {
    display: table;
    padding: 0px 12px 10px;
    .row {
      display: table-row;
    }
    .cell,
    .commentWrapper {
      display: table-cell;
      vertical-align: middle;
      padding-right: 4px;
      padding-left: 4px;
      padding-top: 4px;
    }
    .avatarWrapper {
      width: 32px;
      display: table-cell;
      vertical-align: top;
      padding-right: 4px;
      padding-left: 4px;
      padding-top: 4px;
    }
    .commentWrapper {
      width: 100%;
      p {
        margin-bottom: 8px;
      }
      .comment {
        padding: 8px;
        border-radius: 5px;
        white-space: pre-line;
        background-color: var(--mui-palette-customGrey-natural);
      }
    }
    .likesWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .liked {
        color: var(--mui-palette-gold-light);
      }
      .numberOfLikes {
        display: flex;
        align-items: center;
        .number {
          color: var(--mui-palette-black-opacity26);
        }
      }
    }
  }

  .hashtag {
    color: var(--mui-palette-green-accent);
  }

  .commentFieldPlaceholder {
    padding: 8px;
    margin-left: 8px;
    width: 100%;
    color: var(--mui-palette-black-black026);
    border: 1px solid var(--mui-palette-black-opacity06);
    border-radius: 5px;
  }
}

.noPostContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: var(--mui-palette-white-opacity100);

  img {
    margin-bottom: 16px;
  }
  h3 {
    margin-bottom: 8px;
    color: var(--mui-palette-black-opacity87);
  }
  p {
    color: var(--mui-palette-black-opacity38);
    height: 63px;
    margin-bottom: 50%;
  }
}
