.attendeesListContainer {
   display: flex;
   flex-direction: column !important;
   overflow: hidden;
   background-color: var(--mui-palette-background-default);
}

.attendeesListWrapper {
   padding-top: 12px;
   overflow: auto;
   .count {
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 8px;
      span {
         color: var(--mui-palette-black-black038);
         &:first-child {
            color: var(--mui-palette-primary-main);
         }
      }
   }
   .checkedInText {
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 4px;
      font-weight: 700;
      color: var(--mui-palette-text-primary);
      text-transform: uppercase;
   }
   .attendeesItem {
      padding-top: 8px;
      padding-bottom: 8px;
      gap: 12px;
      margin-bottom: 8px;
      &:last-child {
         margin-bottom: 0;
      }
   }
   .attendeesInformation {
      margin: 0;
      p {
         color: var(--mui-palette-black-black038);
         padding-top: 4px;
         &:first-child {
            padding-top: 0;
            color: var(--mui-palette-black-opacity100);
         }
      }
   }
}

.filterContainer {
   .filterField {
      padding: 4px 0;
   }
   .filterTitle {
      line-height: normal;
      padding-top: 8px;
      p {
         color: var(--mui-palette-black-black038);
      }
   }
   .filterItem {
      padding-top: 16px;
      padding-bottom: 16px;
      gap: 24px;
      color: var(--mui-palette-text-primary);
      &.active,
      &.active:hover {
         background-color: transparent;
      }
      p {
         line-height: 24px;
      }
      img {
         display: block;
         width: 24px;
         height: 24px;
      }
   }
}

.filterHeader {
   .resetFilterButton {
      display: block;
      text-decoration: none;
      color: var(--mui-palette-primary-main);
   }
}
